/** @file Helper functions for timezones */
// import moment from 'moment';
import dayjs from "dayjs";


/**
 * Retrieves static list of timezones
 *
 * @function getTimezones
*/
export function getTimezones() {
    return [
        { country_code: 'ZW', tz: 'Africa/Harare', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'ZM', tz: 'Africa/Lusaka', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'ZA', tz: 'Africa/Johannesburg', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'YT', tz: 'Indian/Mayotte', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'YE', tz: 'Asia/Aden', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'WS', tz: 'Pacific/Apia', offset: '+13:00', dst_offset: '+14:00' },
        { country_code: 'WS', tz: 'Pacific/Samoa', offset: '-11:00', dst_offset: '-11:00' },
        { country_code: 'WS', tz: 'US/Samoa', offset: '-11:00', dst_offset: '-11:00' },
        { country_code: 'WF', tz: 'Pacific/Wallis', offset: '+12:00', dst_offset: '+12:00' },
        { country_code: 'VU', tz: 'Pacific/Efate', offset: '+11:00', dst_offset: '+11:00' },
        { country_code: 'VN', tz: 'Asia/Ho_Chi_Minh', offset: '+07:00', dst_offset: '+07:00' },
        { country_code: 'VN', tz: 'Asia/Saigon', offset: '+07:00', dst_offset: '+07:00' },
        { country_code: 'VI', tz: 'America/St_Thomas', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'VI', tz: 'America/Virgin', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'VG', tz: 'America/Tortola', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'VE', tz: 'America/Caracas', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'VC', tz: 'America/St_Vincent', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'VA', tz: 'Europe/Vatican', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'UZ', tz: 'Asia/Samarkand', offset: '+05:00', dst_offset: '+05:00' },
        { country_code: 'UZ', tz: 'Asia/Tashkent', offset: '+05:00', dst_offset: '+05:00' },
        { country_code: 'UY', tz: 'America/Montevideo', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'US', tz: 'America/Adak', offset: '-10:00', dst_offset: '-09:00' },
        { country_code: 'US', tz: 'America/Anchorage', offset: '-09:00', dst_offset: '-08:00' },
        { country_code: 'US', tz: 'America/Atka', offset: '-10:00', dst_offset: '-09:00' },
        { country_code: 'US', tz: 'America/Boise', offset: '-07:00', dst_offset: '-06:00' },
        { country_code: 'US', tz: 'America/Chicago', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'US', tz: 'America/Denver', offset: '-07:00', dst_offset: '-06:00' },
        { country_code: 'US', tz: 'America/Detroit', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'US', tz: 'America/Fort_Wayne', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'US', tz: 'America/Indiana/Indianapolis', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'US', tz: 'America/Indiana/Knox', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'US', tz: 'America/Indiana/Marengo', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'US', tz: 'America/Indiana/Petersburg', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'US', tz: 'America/Indiana/Tell_City', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'US', tz: 'America/Indiana/Vevay', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'US', tz: 'America/Indiana/Vincennes', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'US', tz: 'America/Indiana/Winamac', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'US', tz: 'America/Indianapolis', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'US', tz: 'America/Juneau', offset: '-09:00', dst_offset: '-08:00' },
        { country_code: 'US', tz: 'America/Kentucky/Louisville', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'US', tz: 'America/Kentucky/Monticello', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'US', tz: 'America/Knox_IN', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'US', tz: 'America/Los_Angeles', offset: '-08:00', dst_offset: '-07:00' },
        { country_code: 'US', tz: 'America/Louisville', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'US', tz: 'America/Menominee', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'US', tz: 'America/Metlakatla', offset: '-09:00', dst_offset: '-08:00' },
        { country_code: 'US', tz: 'America/New_York', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'US', tz: 'America/Nome', offset: '-09:00', dst_offset: '-08:00' },
        { country_code: 'US', tz: 'America/North_Dakota/Beulah', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'US', tz: 'America/North_Dakota/Center', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'US', tz: 'America/North_Dakota/New_Salem', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'US', tz: 'America/Phoenix', offset: '-07:00', dst_offset: '-07:00' },
        { country_code: 'US', tz: 'America/Shiprock', offset: '-07:00', dst_offset: '-06:00' },
        { country_code: 'US', tz: 'America/Sitka', offset: '-09:00', dst_offset: '-08:00' },
        { country_code: 'US', tz: 'America/Yakutat', offset: '-09:00', dst_offset: '-08:00' },
        { country_code: 'US', tz: 'Navajo', offset: '-07:00', dst_offset: '-06:00' },
        { country_code: 'US', tz: 'Pacific/Honolulu', offset: '-10:00', dst_offset: '-10:00' },
        { country_code: 'US', tz: 'US/Alaska', offset: '-09:00', dst_offset: '-08:00' },
        { country_code: 'US', tz: 'US/Aleutian', offset: '-10:00', dst_offset: '-09:00' },
        { country_code: 'US', tz: 'US/Arizona', offset: '-07:00', dst_offset: '-07:00' },
        { country_code: 'US', tz: 'US/Central', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'US', tz: 'US/East-Indiana', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'US', tz: 'US/Eastern', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'US', tz: 'US/Hawaii', offset: '-10:00', dst_offset: '-10:00' },
        { country_code: 'US', tz: 'US/Indiana-Starke', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'US', tz: 'US/Michigan', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'US', tz: 'US/Mountain', offset: '-07:00', dst_offset: '-06:00' },
        { country_code: 'US', tz: 'US/Pacific', offset: '-08:00', dst_offset: '-07:00' },
        { country_code: 'UM', tz: 'Pacific/Johnston', offset: '-10:00', dst_offset: '-10:00' },
        { country_code: 'UM', tz: 'Pacific/Midway', offset: '-11:00', dst_offset: '-11:00' },
        { country_code: 'UM', tz: 'Pacific/Wake', offset: '+12:00', dst_offset: '+12:00' },
        { country_code: 'UG', tz: 'Africa/Kampala', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'UA', tz: 'Europe/Kiev', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'UA', tz: 'Europe/Simferopol', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'UA', tz: 'Europe/Uzhgorod', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'UA', tz: 'Europe/Zaporozhye', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'TZ', tz: 'Africa/Dar_es_Salaam', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'TW', tz: 'Asia/Taipei', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'TW', tz: 'ROC', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'TV', tz: 'Pacific/Funafuti', offset: '+12:00', dst_offset: '+12:00' },
        { country_code: 'TT', tz: 'America/Port_of_Spain', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'TR', tz: 'Asia/Istanbul', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'TR', tz: 'Europe/Istanbul', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'TR', tz: 'Turkey', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'TO', tz: 'Pacific/Tongatapu', offset: '+13:00', dst_offset: '+13:00' },
        { country_code: 'TN', tz: 'Africa/Tunis', offset: '+01:00', dst_offset: '+01:00' },
        { country_code: 'TM', tz: 'Asia/Ashgabat', offset: '+05:00', dst_offset: '+05:00' },
        { country_code: 'TM', tz: 'Asia/Ashkhabad', offset: '+05:00', dst_offset: '+05:00' },
        { country_code: 'TL', tz: 'Asia/Dili', offset: '+09:00', dst_offset: '+09:00' },
        { country_code: 'TK', tz: 'Pacific/Fakaofo', offset: '+13:00', dst_offset: '+13:00' },
        { country_code: 'TJ', tz: 'Asia/Dushanbe', offset: '+05:00', dst_offset: '+05:00' },
        { country_code: 'TH', tz: 'Asia/Bangkok', offset: '+07:00', dst_offset: '+07:00' },
        { country_code: 'TG', tz: 'Africa/Lome', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'TF', tz: 'Indian/Kerguelen', offset: '+05:00', dst_offset: '+05:00' },
        { country_code: 'TD', tz: 'Africa/Ndjamena', offset: '+01:00', dst_offset: '+01:00' },
        { country_code: 'TC', tz: 'America/Grand_Turk', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'SZ', tz: 'Africa/Mbabane', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'SY', tz: 'Asia/Damascus', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'SX', tz: 'America/Lower_Princes', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'SV', tz: 'America/El_Salvador', offset: '-06:00', dst_offset: '-06:00' },
        { country_code: 'ST', tz: 'Africa/Sao_Tome', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'SS', tz: 'Africa/Juba', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'SR', tz: 'America/Paramaribo', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'SO', tz: 'Africa/Mogadishu', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'SN', tz: 'Africa/Dakar', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'SM', tz: 'Europe/San_Marino', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'SL', tz: 'Africa/Freetown', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'SK', tz: 'Europe/Bratislava', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'SJ', tz: 'Arctic/Longyearbyen', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'SJ', tz: 'Atlantic/Jan_Mayen', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'SI', tz: 'Europe/Ljubljana', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'SH', tz: 'Atlantic/St_Helena', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'SG', tz: 'Asia/Singapore', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'SG', tz: 'Singapore', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'SE', tz: 'Europe/Stockholm', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'SD', tz: 'Africa/Khartoum', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'SC', tz: 'Indian/Mahe', offset: '+04:00', dst_offset: '+04:00' },
        { country_code: 'SB', tz: 'Pacific/Guadalcanal', offset: '+11:00', dst_offset: '+11:00' },
        { country_code: 'SA', tz: 'Asia/Riyadh', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'RW', tz: 'Africa/Kigali', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'RU', tz: 'Asia/Anadyr', offset: '+12:00', dst_offset: '+12:00' },
        { country_code: 'RU', tz: 'Asia/Barnaul', offset: '+07:00', dst_offset: '+07:00' },
        { country_code: 'RU', tz: 'Asia/Chita', offset: '+09:00', dst_offset: '+09:00' },
        { country_code: 'RU', tz: 'Asia/Irkutsk', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'RU', tz: 'Asia/Kamchatka', offset: '+12:00', dst_offset: '+12:00' },
        { country_code: 'RU', tz: 'Asia/Khandyga', offset: '+09:00', dst_offset: '+09:00' },
        { country_code: 'RU', tz: 'Asia/Krasnoyarsk', offset: '+07:00', dst_offset: '+07:00' },
        { country_code: 'RU', tz: 'Asia/Magadan', offset: '+11:00', dst_offset: '+11:00' },
        { country_code: 'RU', tz: 'Asia/Novokuznetsk', offset: '+07:00', dst_offset: '+07:00' },
        { country_code: 'RU', tz: 'Asia/Novosibirsk', offset: '+07:00', dst_offset: '+07:00' },
        { country_code: 'RU', tz: 'Asia/Omsk', offset: '+06:00', dst_offset: '+06:00' },
        { country_code: 'RU', tz: 'Asia/Sakhalin', offset: '+11:00', dst_offset: '+11:00' },
        { country_code: 'RU', tz: 'Asia/Srednekolymsk', offset: '+11:00', dst_offset: '+11:00' },
        { country_code: 'RU', tz: 'Asia/Tomsk', offset: '+07:00', dst_offset: '+07:00' },
        { country_code: 'RU', tz: 'Asia/Ust-Nera', offset: '+10:00', dst_offset: '+10:00' },
        { country_code: 'RU', tz: 'Asia/Vladivostok', offset: '+10:00', dst_offset: '+10:00' },
        { country_code: 'RU', tz: 'Asia/Yakutsk', offset: '+09:00', dst_offset: '+09:00' },
        { country_code: 'RU', tz: 'Asia/Yekaterinburg', offset: '+05:00', dst_offset: '+05:00' },
        { country_code: 'RU', tz: 'Europe/Astrakhan', offset: '+04:00', dst_offset: '+04:00' },
        { country_code: 'RU', tz: 'Europe/Kaliningrad', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'RU', tz: 'Europe/Kirov', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'RU', tz: 'Europe/Moscow', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'RU', tz: 'Europe/Samara', offset: '+04:00', dst_offset: '+04:00' },
        { country_code: 'RU', tz: 'Europe/Saratov', offset: '+04:00', dst_offset: '+04:00' },
        { country_code: 'RU', tz: 'Europe/Ulyanovsk', offset: '+04:00', dst_offset: '+04:00' },
        { country_code: 'RU', tz: 'Europe/Volgograd', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'RU', tz: 'W-SU', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'RS', tz: 'Europe/Belgrade', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'RO', tz: 'Europe/Bucharest', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'RE', tz: 'Indian/Reunion', offset: '+04:00', dst_offset: '+04:00' },
        { country_code: 'QA', tz: 'Asia/Qatar', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'PY', tz: 'America/Asuncion', offset: '-04:00', dst_offset: '-03:00' },
        { country_code: 'PW', tz: 'Pacific/Palau', offset: '+09:00', dst_offset: '+09:00' },
        { country_code: 'PT', tz: 'Atlantic/Azores', offset: '-01:00', dst_offset: '+00:00' },
        { country_code: 'PT', tz: 'Atlantic/Madeira', offset: '+00:00', dst_offset: '+01:00' },
        { country_code: 'PT', tz: 'Europe/Lisbon', offset: '+00:00', dst_offset: '+01:00' },
        { country_code: 'PT', tz: 'Portugal', offset: '+00:00', dst_offset: '+01:00' },
        { country_code: 'PS', tz: 'Asia/Gaza', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'PS', tz: 'Asia/Hebron', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'PR', tz: 'America/Puerto_Rico', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'PN', tz: 'Pacific/Pitcairn', offset: '-08:00', dst_offset: '-08:00' },
        { country_code: 'PM', tz: 'America/Miquelon', offset: '-03:00', dst_offset: '-02:00' },
        { country_code: 'PL', tz: 'Europe/Warsaw', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'PL', tz: 'Poland', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'PK', tz: 'Asia/Karachi', offset: '+05:00', dst_offset: '+05:00' },
        { country_code: 'PH', tz: 'Asia/Manila', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'PG', tz: 'Pacific/Bougainville', offset: '+11:00', dst_offset: '+11:00' },
        { country_code: 'PG', tz: 'Pacific/Port_Moresby', offset: '+10:00', dst_offset: '+10:00' },
        { country_code: 'PF', tz: 'Pacific/Gambier', offset: '-09:00', dst_offset: '-09:00' },
        { country_code: 'PF', tz: 'Pacific/Marquesas', offset: '-09:30', dst_offset: '-09:30' },
        { country_code: 'PF', tz: 'Pacific/Tahiti', offset: '-10:00', dst_offset: '-10:00' },
        { country_code: 'PE', tz: 'America/Lima', offset: '-05:00', dst_offset: '-05:00' },
        { country_code: 'PA', tz: 'America/Panama', offset: '-05:00', dst_offset: '-05:00' },
        { country_code: 'OM', tz: 'Asia/Muscat', offset: '+04:00', dst_offset: '+04:00' },
        { country_code: 'NZ', tz: 'NZ', offset: '+12:00', dst_offset: '+13:00' },
        { country_code: 'NZ', tz: 'NZ-CHAT', offset: '+12:45', dst_offset: '+13:45' },
        { country_code: 'NZ', tz: 'Pacific/Auckland', offset: '+12:00', dst_offset: '+13:00' },
        { country_code: 'NZ', tz: 'Pacific/Chatham', offset: '+12:45', dst_offset: '+13:45' },
        { country_code: 'NU', tz: 'Pacific/Niue', offset: '-11:00', dst_offset: '-11:00' },
        { country_code: 'NR', tz: 'Pacific/Nauru', offset: '+12:00', dst_offset: '+12:00' },
        { country_code: 'NP', tz: 'Asia/Kathmandu', offset: '+05:45', dst_offset: '+05:45' },
        { country_code: 'NP', tz: 'Asia/Katmandu', offset: '+05:45', dst_offset: '+05:45' },
        { country_code: 'NO', tz: 'Europe/Oslo', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'NL', tz: 'Europe/Amsterdam', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'NI', tz: 'America/Managua', offset: '-06:00', dst_offset: '-06:00' },
        { country_code: 'NG', tz: 'Africa/Lagos', offset: '+01:00', dst_offset: '+01:00' },
        { country_code: 'NF', tz: 'Pacific/Norfolk', offset: '+11:00', dst_offset: '+12:00' },
        { country_code: 'NE', tz: 'Africa/Niamey', offset: '+01:00', dst_offset: '+01:00' },
        { country_code: 'NC', tz: 'Pacific/Noumea', offset: '+11:00', dst_offset: '+11:00' },
        { country_code: 'NA', tz: 'Africa/Windhoek', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'MZ', tz: 'Africa/Maputo', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'MY', tz: 'Asia/Kuala_Lumpur', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'MY', tz: 'Asia/Kuching', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'MX', tz: 'America/Bahia_Banderas', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'MX', tz: 'America/Cancun', offset: '-05:00', dst_offset: '-05:00' },
        { country_code: 'MX', tz: 'America/Chihuahua', offset: '-07:00', dst_offset: '-06:00' },
        { country_code: 'MX', tz: 'America/Ensenada', offset: '-08:00', dst_offset: '-07:00' },
        { country_code: 'MX', tz: 'America/Hermosillo', offset: '-07:00', dst_offset: '-07:00' },
        { country_code: 'MX', tz: 'America/Matamoros', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'MX', tz: 'America/Mazatlan', offset: '-07:00', dst_offset: '-06:00' },
        { country_code: 'MX', tz: 'America/Merida', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'MX', tz: 'America/Mexico_City', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'MX', tz: 'America/Monterrey', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'MX', tz: 'America/Ojinaga', offset: '-07:00', dst_offset: '-06:00' },
        { country_code: 'MX', tz: 'America/Santa_Isabel', offset: '-08:00', dst_offset: '-07:00' },
        { country_code: 'MX', tz: 'America/Tijuana', offset: '-08:00', dst_offset: '-07:00' },
        { country_code: 'MX', tz: 'Mexico/BajaNorte', offset: '-08:00', dst_offset: '-07:00' },
        { country_code: 'MX', tz: 'Mexico/BajaSur', offset: '-07:00', dst_offset: '-06:00' },
        { country_code: 'MX', tz: 'Mexico/General', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'MW', tz: 'Africa/Blantyre', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'MV', tz: 'Indian/Maldives', offset: '+05:00', dst_offset: '+05:00' },
        { country_code: 'MU', tz: 'Indian/Mauritius', offset: '+04:00', dst_offset: '+04:00' },
        { country_code: 'MT', tz: 'Europe/Malta', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'MS', tz: 'America/Montserrat', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'MR', tz: 'Africa/Nouakchott', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'MQ', tz: 'America/Martinique', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'MP', tz: 'Pacific/Saipan', offset: '+10:00', dst_offset: '+10:00' },
        { country_code: 'MO', tz: 'Asia/Macao', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'MO', tz: 'Asia/Macau', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'MN', tz: 'Asia/Choibalsan', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'MN', tz: 'Asia/Hovd', offset: '+07:00', dst_offset: '+07:00' },
        { country_code: 'MN', tz: 'Asia/Ulaanbaatar', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'MN', tz: 'Asia/Ulan_Bator', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'MM', tz: 'Asia/Rangoon', offset: '+06:30', dst_offset: '+06:30' },
        { country_code: 'MM', tz: 'Asia/Yangon', offset: '+06:30', dst_offset: '+06:30' },
        { country_code: 'ML', tz: 'Africa/Bamako', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'ML', tz: 'Africa/Timbuktu', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'MK', tz: 'Europe/Skopje', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'MH', tz: 'Kwajalein', offset: '+12:00', dst_offset: '+12:00' },
        { country_code: 'MH', tz: 'Pacific/Kwajalein', offset: '+12:00', dst_offset: '+12:00' },
        { country_code: 'MH', tz: 'Pacific/Majuro', offset: '+12:00', dst_offset: '+12:00' },
        { country_code: 'MG', tz: 'Indian/Antananarivo', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'MF', tz: 'America/Marigot', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'ME', tz: 'Europe/Podgorica', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'MD', tz: 'Europe/Chisinau', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'MD', tz: 'Europe/Tiraspol', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'MC', tz: 'Europe/Monaco', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'MA', tz: 'Africa/Casablanca', offset: '+01:00', dst_offset: '+00:00' },
        { country_code: 'LY', tz: 'Africa/Tripoli', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'LY', tz: 'Libya', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'LV', tz: 'Europe/Riga', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'LU', tz: 'Europe/Luxembourg', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'LT', tz: 'Europe/Vilnius', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'LS', tz: 'Africa/Maseru', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'LR', tz: 'Africa/Monrovia', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'LK', tz: 'Asia/Colombo', offset: '+05:30', dst_offset: '+05:30' },
        { country_code: 'LI', tz: 'Europe/Vaduz', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'LC', tz: 'America/St_Lucia', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'LB', tz: 'Asia/Beirut', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'LA', tz: 'Asia/Vientiane', offset: '+07:00', dst_offset: '+07:00' },
        { country_code: 'KZ', tz: 'Asia/Almaty', offset: '+06:00', dst_offset: '+06:00' },
        { country_code: 'KZ', tz: 'Asia/Aqtau', offset: '+05:00', dst_offset: '+05:00' },
        { country_code: 'KZ', tz: 'Asia/Aqtobe', offset: '+05:00', dst_offset: '+05:00' },
        { country_code: 'KZ', tz: 'Asia/Atyrau', offset: '+05:00', dst_offset: '+05:00' },
        { country_code: 'KZ', tz: 'Asia/Oral', offset: '+05:00', dst_offset: '+05:00' },
        { country_code: 'KZ', tz: 'Asia/Qostanay', offset: '+06:00', dst_offset: '+06:00' },
        { country_code: 'KZ', tz: 'Asia/Qyzylorda', offset: '+05:00', dst_offset: '+05:00' },
        { country_code: 'KY', tz: 'America/Cayman', offset: '-05:00', dst_offset: '-05:00' },
        { country_code: 'KW', tz: 'Asia/Kuwait', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'KR', tz: 'Asia/Seoul', offset: '+09:00', dst_offset: '+09:00' },
        { country_code: 'KR', tz: 'ROK', offset: '+09:00', dst_offset: '+09:00' },
        { country_code: 'KP', tz: 'Asia/Pyongyang', offset: '+09:00', dst_offset: '+09:00' },
        { country_code: 'KN', tz: 'America/St_Kitts', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'KM', tz: 'Indian/Comoro', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'KI', tz: 'Pacific/Enderbury', offset: '+13:00', dst_offset: '+13:00' },
        { country_code: 'KI', tz: 'Pacific/Kiritimati', offset: '+14:00', dst_offset: '+14:00' },
        { country_code: 'KI', tz: 'Pacific/Tarawa', offset: '+12:00', dst_offset: '+12:00' },
        { country_code: 'KH', tz: 'Asia/Phnom_Penh', offset: '+07:00', dst_offset: '+07:00' },
        { country_code: 'KG', tz: 'Asia/Bishkek', offset: '+06:00', dst_offset: '+06:00' },
        { country_code: 'KE', tz: 'Africa/Nairobi', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'JP', tz: 'Asia/Tokyo', offset: '+09:00', dst_offset: '+09:00' },
        { country_code: 'JP', tz: 'Japan', offset: '+09:00', dst_offset: '+09:00' },
        { country_code: 'JO', tz: 'Asia/Amman', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'JM', tz: 'America/Jamaica', offset: '-05:00', dst_offset: '-05:00' },
        { country_code: 'JM', tz: 'Jamaica', offset: '-05:00', dst_offset: '-05:00' },
        { country_code: 'JE', tz: 'Europe/Jersey', offset: '+00:00', dst_offset: '+01:00' },
        { country_code: 'IT', tz: 'Europe/Rome', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'IS', tz: 'Atlantic/Reykjavik', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'IS', tz: 'Iceland', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'IR', tz: 'Asia/Tehran', offset: '+03:30', dst_offset: '+04:30' },
        { country_code: 'IR', tz: 'Iran', offset: '+03:30', dst_offset: '+04:30' },
        { country_code: 'IQ', tz: 'Asia/Baghdad', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'IO', tz: 'Indian/Chagos', offset: '+06:00', dst_offset: '+06:00' },
        { country_code: 'IN', tz: 'Asia/Calcutta', offset: '+05:30', dst_offset: '+05:30' },
        { country_code: 'IN', tz: 'Asia/Kolkata', offset: '+05:30', dst_offset: '+05:30' },
        { country_code: 'IM', tz: 'Europe/Isle_of_Man', offset: '+00:00', dst_offset: '+01:00' },
        { country_code: 'IL', tz: 'Asia/Jerusalem', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'IL', tz: 'Asia/Tel_Aviv', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'IL', tz: 'Israel', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'IE', tz: 'Eire', offset: '+01:00', dst_offset: '+00:00' },
        { country_code: 'IE', tz: 'Europe/Dublin', offset: '+01:00', dst_offset: '+00:00' },
        { country_code: 'ID', tz: 'Asia/Jakarta', offset: '+07:00', dst_offset: '+07:00' },
        { country_code: 'ID', tz: 'Asia/Jayapura', offset: '+09:00', dst_offset: '+09:00' },
        { country_code: 'ID', tz: 'Asia/Makassar', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'ID', tz: 'Asia/Pontianak', offset: '+07:00', dst_offset: '+07:00' },
        { country_code: 'ID', tz: 'Asia/Ujung_Pandang', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'HU', tz: 'Europe/Budapest', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'HT', tz: 'America/Port-au-Prince', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'HR', tz: 'Europe/Zagreb', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'HN', tz: 'America/Tegucigalpa', offset: '-06:00', dst_offset: '-06:00' },
        { country_code: 'HK', tz: 'Asia/Hong_Kong', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'HK', tz: 'Hongkong', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'GY', tz: 'America/Guyana', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'GW', tz: 'Africa/Bissau', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'GU', tz: 'Pacific/Guam', offset: '+10:00', dst_offset: '+10:00' },
        { country_code: 'GT', tz: 'America/Guatemala', offset: '-06:00', dst_offset: '-06:00' },
        { country_code: 'GS', tz: 'Atlantic/South_Georgia', offset: '-02:00', dst_offset: '-02:00' },
        { country_code: 'GR', tz: 'Europe/Athens', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'GQ', tz: 'Africa/Malabo', offset: '+01:00', dst_offset: '+01:00' },
        { country_code: 'GP', tz: 'America/Guadeloupe', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'GN', tz: 'Africa/Conakry', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'GM', tz: 'Africa/Banjul', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'GL', tz: 'America/Danmarkshavn', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'GL', tz: 'America/Godthab', offset: '-03:00', dst_offset: '-02:00' },
        { country_code: 'GL', tz: 'America/Nuuk', offset: '-03:00', dst_offset: '-02:00' },
        { country_code: 'GL', tz: 'America/Scoresbysund', offset: '-01:00', dst_offset: '+00:00' },
        { country_code: 'GL', tz: 'America/Thule', offset: '-04:00', dst_offset: '-03:00' },
        { country_code: 'GI', tz: 'Europe/Gibraltar', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'GH', tz: 'Africa/Accra', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'GG', tz: 'Europe/Guernsey', offset: '+00:00', dst_offset: '+01:00' },
        { country_code: 'GF', tz: 'America/Cayenne', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'GE', tz: 'Asia/Tbilisi', offset: '+04:00', dst_offset: '+04:00' },
        { country_code: 'GD', tz: 'America/Grenada', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'GB', tz: 'Europe/Belfast', offset: '+00:00', dst_offset: '+01:00' },
        { country_code: 'GB', tz: 'Europe/London', offset: '+00:00', dst_offset: '+01:00' },
        { country_code: 'GB', tz: 'GB', offset: '+00:00', dst_offset: '+01:00' },
        { country_code: 'GB', tz: 'GB-Eire', offset: '+00:00', dst_offset: '+01:00' },
        { country_code: 'GA', tz: 'Africa/Libreville', offset: '+01:00', dst_offset: '+01:00' },
        { country_code: 'FR', tz: 'Europe/Paris', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'FO', tz: 'Atlantic/Faeroe', offset: '+00:00', dst_offset: '+01:00' },
        { country_code: 'FO', tz: 'Atlantic/Faroe', offset: '+00:00', dst_offset: '+01:00' },
        { country_code: 'FM', tz: 'Pacific/Chuuk', offset: '+10:00', dst_offset: '+10:00' },
        { country_code: 'FM', tz: 'Pacific/Kosrae', offset: '+11:00', dst_offset: '+11:00' },
        { country_code: 'FM', tz: 'Pacific/Pohnpei', offset: '+11:00', dst_offset: '+11:00' },
        { country_code: 'FM', tz: 'Pacific/Ponape', offset: '+11:00', dst_offset: '+11:00' },
        { country_code: 'FM', tz: 'Pacific/Truk', offset: '+10:00', dst_offset: '+10:00' },
        { country_code: 'FM', tz: 'Pacific/Yap', offset: '+10:00', dst_offset: '+10:00' },
        { country_code: 'FK', tz: 'Atlantic/Stanley', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'FJ', tz: 'Pacific/Fiji', offset: '+12:00', dst_offset: '+13:00' },
        { country_code: 'FI', tz: 'Europe/Helsinki', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'ET', tz: 'Africa/Addis_Ababa', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'ES', tz: 'Africa/Ceuta', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'ES', tz: 'Atlantic/Canary', offset: '+00:00', dst_offset: '+01:00' },
        { country_code: 'ES', tz: 'Europe/Madrid', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'ER', tz: 'Africa/Asmara', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'ER', tz: 'Africa/Asmera', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'EH', tz: 'Africa/El_Aaiun', offset: '+01:00', dst_offset: '+00:00' },
        { country_code: 'EG', tz: 'Africa/Cairo', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'EG', tz: 'Egypt', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'EE', tz: 'Europe/Tallinn', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'EC', tz: 'America/Guayaquil', offset: '-05:00', dst_offset: '-05:00' },
        { country_code: 'EC', tz: 'Pacific/Galapagos', offset: '-06:00', dst_offset: '-06:00' },
        { country_code: 'DZ', tz: 'Africa/Algiers', offset: '+01:00', dst_offset: '+01:00' },
        { country_code: 'DO', tz: 'America/Santo_Domingo', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'DM', tz: 'America/Dominica', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'DK', tz: 'Europe/Copenhagen', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'DJ', tz: 'Africa/Djibouti', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'DE', tz: 'Europe/Berlin', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'DE', tz: 'Europe/Busingen', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'CZ', tz: 'Europe/Prague', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'CY', tz: 'Asia/Famagusta', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'CY', tz: 'Asia/Nicosia', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'CY', tz: 'Europe/Nicosia', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'CX', tz: 'Indian/Christmas', offset: '+07:00', dst_offset: '+07:00' },
        { country_code: 'CW', tz: 'America/Curacao', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'CV', tz: 'Atlantic/Cape_Verde', offset: '-01:00', dst_offset: '-01:00' },
        { country_code: 'CU', tz: 'America/Havana', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'CU', tz: 'Cuba', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'CR', tz: 'America/Costa_Rica', offset: '-06:00', dst_offset: '-06:00' },
        { country_code: 'CO', tz: 'America/Bogota', offset: '-05:00', dst_offset: '-05:00' },
        { country_code: 'CN', tz: 'Asia/Chongqing', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'CN', tz: 'Asia/Chungking', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'CN', tz: 'Asia/Harbin', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'CN', tz: 'Asia/Kashgar', offset: '+06:00', dst_offset: '+06:00' },
        { country_code: 'CN', tz: 'Asia/Shanghai', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'CN', tz: 'Asia/Urumqi', offset: '+06:00', dst_offset: '+06:00' },
        { country_code: 'CN', tz: 'PRC', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'CM', tz: 'Africa/Douala', offset: '+01:00', dst_offset: '+01:00' },
        { country_code: 'CL', tz: 'America/Punta_Arenas', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'CL', tz: 'America/Santiago', offset: '-04:00', dst_offset: '-03:00' },
        { country_code: 'CL', tz: 'Chile/Continental', offset: '-04:00', dst_offset: '-03:00' },
        { country_code: 'CL', tz: 'Chile/EasterIsland', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'CL', tz: 'Pacific/Easter', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'CK', tz: 'Pacific/Rarotonga', offset: '-10:00', dst_offset: '-10:00' },
        { country_code: 'CI', tz: 'Africa/Abidjan', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'CH', tz: 'Europe/Zurich', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'CG', tz: 'Africa/Brazzaville', offset: '+01:00', dst_offset: '+01:00' },
        { country_code: 'CF', tz: 'Africa/Bangui', offset: '+01:00', dst_offset: '+01:00' },
        { country_code: 'CD', tz: 'Africa/Kinshasa', offset: '+01:00', dst_offset: '+01:00' },
        { country_code: 'CD', tz: 'Africa/Lubumbashi', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'CC', tz: 'Indian/Cocos', offset: '+06:30', dst_offset: '+06:30' },
        { country_code: 'CA', tz: 'America/Atikokan', offset: '-05:00', dst_offset: '-05:00' },
        { country_code: 'CA', tz: 'America/Blanc-Sablon', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'CA', tz: 'America/Cambridge_Bay', offset: '-07:00', dst_offset: '-06:00' },
        { country_code: 'CA', tz: 'America/Coral_Harbour', offset: '-05:00', dst_offset: '-05:00' },
        { country_code: 'CA', tz: 'America/Creston', offset: '-07:00', dst_offset: '-07:00' },
        { country_code: 'CA', tz: 'America/Dawson', offset: '-07:00', dst_offset: '-07:00' },
        { country_code: 'CA', tz: 'America/Dawson_Creek', offset: '-07:00', dst_offset: '-07:00' },
        { country_code: 'CA', tz: 'America/Edmonton', offset: '-07:00', dst_offset: '-06:00' },
        { country_code: 'CA', tz: 'America/Fort_Nelson', offset: '-07:00', dst_offset: '-07:00' },
        { country_code: 'CA', tz: 'America/Glace_Bay', offset: '-04:00', dst_offset: '-03:00' },
        { country_code: 'CA', tz: 'America/Goose_Bay', offset: '-04:00', dst_offset: '-03:00' },
        { country_code: 'CA', tz: 'America/Halifax', offset: '-04:00', dst_offset: '-03:00' },
        { country_code: 'CA', tz: 'America/Inuvik', offset: '-07:00', dst_offset: '-06:00' },
        { country_code: 'CA', tz: 'America/Iqaluit', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'CA', tz: 'America/Moncton', offset: '-04:00', dst_offset: '-03:00' },
        { country_code: 'CA', tz: 'America/Montreal', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'CA', tz: 'America/Nipigon', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'CA', tz: 'America/Pangnirtung', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'CA', tz: 'America/Rainy_River', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'CA', tz: 'America/Rankin_Inlet', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'CA', tz: 'America/Regina', offset: '-06:00', dst_offset: '-06:00' },
        { country_code: 'CA', tz: 'America/Resolute', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'CA', tz: 'America/St_Johns', offset: '-03:30', dst_offset: '-02:30' },
        { country_code: 'CA', tz: 'America/Swift_Current', offset: '-06:00', dst_offset: '-06:00' },
        { country_code: 'CA', tz: 'America/Thunder_Bay', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'CA', tz: 'America/Toronto', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'CA', tz: 'America/Vancouver', offset: '-08:00', dst_offset: '-07:00' },
        { country_code: 'CA', tz: 'America/Whitehorse', offset: '-07:00', dst_offset: '-07:00' },
        { country_code: 'CA', tz: 'America/Winnipeg', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'CA', tz: 'America/Yellowknife', offset: '-07:00', dst_offset: '-06:00' },
        { country_code: 'CA', tz: 'Canada/Atlantic', offset: '-04:00', dst_offset: '-03:00' },
        { country_code: 'CA', tz: 'Canada/Central', offset: '-06:00', dst_offset: '-05:00' },
        { country_code: 'CA', tz: 'Canada/Eastern', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'CA', tz: 'Canada/Mountain', offset: '-07:00', dst_offset: '-06:00' },
        { country_code: 'CA', tz: 'Canada/Newfoundland', offset: '-03:30', dst_offset: '-02:30' },
        { country_code: 'CA', tz: 'Canada/Pacific', offset: '-08:00', dst_offset: '-07:00' },
        { country_code: 'CA', tz: 'Canada/Saskatchewan', offset: '-06:00', dst_offset: '-06:00' },
        { country_code: 'CA', tz: 'Canada/Yukon', offset: '-07:00', dst_offset: '-07:00' },
        { country_code: 'BZ', tz: 'America/Belize', offset: '-06:00', dst_offset: '-06:00' },
        { country_code: 'BY', tz: 'Europe/Minsk', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'BW', tz: 'Africa/Gaborone', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'BT', tz: 'Asia/Thimbu', offset: '+06:00', dst_offset: '+06:00' },
        { country_code: 'BT', tz: 'Asia/Thimphu', offset: '+06:00', dst_offset: '+06:00' },
        { country_code: 'BS', tz: 'America/Nassau', offset: '-05:00', dst_offset: '-04:00' },
        { country_code: 'BR', tz: 'America/Araguaina', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'BR', tz: 'America/Bahia', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'BR', tz: 'America/Belem', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'BR', tz: 'America/Boa_Vista', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'BR', tz: 'America/Campo_Grande', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'BR', tz: 'America/Cuiaba', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'BR', tz: 'America/Eirunepe', offset: '-05:00', dst_offset: '-05:00' },
        { country_code: 'BR', tz: 'America/Fortaleza', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'BR', tz: 'America/Maceio', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'BR', tz: 'America/Manaus', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'BR', tz: 'America/Noronha', offset: '-02:00', dst_offset: '-02:00' },
        { country_code: 'BR', tz: 'America/Porto_Acre', offset: '-05:00', dst_offset: '-05:00' },
        { country_code: 'BR', tz: 'America/Porto_Velho', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'BR', tz: 'America/Recife', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'BR', tz: 'America/Rio_Branco', offset: '-05:00', dst_offset: '-05:00' },
        { country_code: 'BR', tz: 'America/Santarem', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'BR', tz: 'America/Sao_Paulo', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'BR', tz: 'Brazil/Acre', offset: '-05:00', dst_offset: '-05:00' },
        { country_code: 'BR', tz: 'Brazil/DeNoronha', offset: '-02:00', dst_offset: '-02:00' },
        { country_code: 'BR', tz: 'Brazil/East', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'BR', tz: 'Brazil/West', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'BQ', tz: 'America/Kralendijk', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'BO', tz: 'America/La_Paz', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'BN', tz: 'Asia/Brunei', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'BM', tz: 'Atlantic/Bermuda', offset: '-04:00', dst_offset: '-03:00' },
        { country_code: 'BL', tz: 'America/St_Barthelemy', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'BJ', tz: 'Africa/Porto-Novo', offset: '+01:00', dst_offset: '+01:00' },
        { country_code: 'BI', tz: 'Africa/Bujumbura', offset: '+02:00', dst_offset: '+02:00' },
        { country_code: 'BH', tz: 'Asia/Bahrain', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'BG', tz: 'Europe/Sofia', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'BF', tz: 'Africa/Ouagadougou', offset: '+00:00', dst_offset: '+00:00' },
        { country_code: 'BE', tz: 'Europe/Brussels', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'BD', tz: 'Asia/Dacca', offset: '+06:00', dst_offset: '+06:00' },
        { country_code: 'BD', tz: 'Asia/Dhaka', offset: '+06:00', dst_offset: '+06:00' },
        { country_code: 'BB', tz: 'America/Barbados', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'BA', tz: 'Europe/Sarajevo', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'AZ', tz: 'Asia/Baku', offset: '+04:00', dst_offset: '+04:00' },
        { country_code: 'AX', tz: 'Europe/Mariehamn', offset: '+02:00', dst_offset: '+03:00' },
        { country_code: 'AW', tz: 'America/Aruba', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'AU', tz: 'Antarctica/Macquarie', offset: '+10:00', dst_offset: '+11:00' },
        { country_code: 'AU', tz: 'Australia/ACT', offset: '+10:00', dst_offset: '+11:00' },
        { country_code: 'AU', tz: 'Australia/Adelaide', offset: '+09:30', dst_offset: '+10:30' },
        { country_code: 'AU', tz: 'Australia/Brisbane', offset: '+10:00', dst_offset: '+10:00' },
        { country_code: 'AU', tz: 'Australia/Broken_Hill', offset: '+09:30', dst_offset: '+10:30' },
        { country_code: 'AU', tz: 'Australia/Canberra', offset: '+10:00', dst_offset: '+11:00' },
        { country_code: 'AU', tz: 'Australia/Currie', offset: '+10:00', dst_offset: '+11:00' },
        { country_code: 'AU', tz: 'Australia/Darwin', offset: '+09:30', dst_offset: '+09:30' },
        { country_code: 'AU', tz: 'Australia/Eucla', offset: '+08:45', dst_offset: '+08:45' },
        { country_code: 'AU', tz: 'Australia/Hobart', offset: '+10:00', dst_offset: '+11:00' },
        { country_code: 'AU', tz: 'Australia/LHI', offset: '+10:30', dst_offset: '+11:00' },
        { country_code: 'AU', tz: 'Australia/Lindeman', offset: '+10:00', dst_offset: '+10:00' },
        { country_code: 'AU', tz: 'Australia/Lord_Howe', offset: '+10:30', dst_offset: '+11:00' },
        { country_code: 'AU', tz: 'Australia/Melbourne', offset: '+10:00', dst_offset: '+11:00' },
        { country_code: 'AU', tz: 'Australia/North', offset: '+09:30', dst_offset: '+09:30' },
        { country_code: 'AU', tz: 'Australia/NSW', offset: '+10:00', dst_offset: '+11:00' },
        { country_code: 'AU', tz: 'Australia/Perth', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'AU', tz: 'Australia/Queensland', offset: '+10:00', dst_offset: '+10:00' },
        { country_code: 'AU', tz: 'Australia/South', offset: '+09:30', dst_offset: '+10:30' },
        { country_code: 'AU', tz: 'Australia/Sydney', offset: '+10:00', dst_offset: '+11:00' },
        { country_code: 'AU', tz: 'Australia/Tasmania', offset: '+10:00', dst_offset: '+11:00' },
        { country_code: 'AU', tz: 'Australia/Victoria', offset: '+10:00', dst_offset: '+11:00' },
        { country_code: 'AU', tz: 'Australia/West', offset: '+08:00', dst_offset: '+08:00' },
        { country_code: 'AU', tz: 'Australia/Yancowinna', offset: '+09:30', dst_offset: '+10:30' },
        { country_code: 'AT', tz: 'Europe/Vienna', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'AS', tz: 'Pacific/Pago_Pago', offset: '-11:00', dst_offset: '-11:00' },
        { country_code: 'AR', tz: 'America/Argentina/Buenos_Aires', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Argentina/Catamarca', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Argentina/ComodRivadavia', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Argentina/Cordoba', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Argentina/Jujuy', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Argentina/La_Rioja', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Argentina/Mendoza', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Argentina/Rio_Gallegos', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Argentina/Salta', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Argentina/San_Juan', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Argentina/San_Luis', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Argentina/Tucuman', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Argentina/Ushuaia', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Buenos_Aires', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Catamarca', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Cordoba', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Jujuy', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Mendoza', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AR', tz: 'America/Rosario', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AQ', tz: 'Antarctica/Casey', offset: '+11:00', dst_offset: '+11:00' },
        { country_code: 'AQ', tz: 'Antarctica/Davis', offset: '+07:00', dst_offset: '+07:00' },
        { country_code: 'AQ', tz: 'Antarctica/DumontDUrville', offset: '+10:00', dst_offset: '+10:00' },
        { country_code: 'AQ', tz: 'Antarctica/Mawson', offset: '+05:00', dst_offset: '+05:00' },
        { country_code: 'AQ', tz: 'Antarctica/McMurdo', offset: '+12:00', dst_offset: '+13:00' },
        { country_code: 'AQ', tz: 'Antarctica/Palmer', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AQ', tz: 'Antarctica/Rothera', offset: '-03:00', dst_offset: '-03:00' },
        { country_code: 'AQ', tz: 'Antarctica/South_Pole', offset: '+12:00', dst_offset: '+13:00' },
        { country_code: 'AQ', tz: 'Antarctica/Syowa', offset: '+03:00', dst_offset: '+03:00' },
        { country_code: 'AQ', tz: 'Antarctica/Troll', offset: '+00:00', dst_offset: '+02:00' },
        { country_code: 'AQ', tz: 'Antarctica/Vostok', offset: '+06:00', dst_offset: '+06:00' },
        { country_code: 'AO', tz: 'Africa/Luanda', offset: '+01:00', dst_offset: '+01:00' },
        { country_code: 'AM', tz: 'Asia/Yerevan', offset: '+04:00', dst_offset: '+04:00' },
        { country_code: 'AL', tz: 'Europe/Tirane', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: 'AI', tz: 'America/Anguilla', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'AG', tz: 'America/Antigua', offset: '-04:00', dst_offset: '-04:00' },
        { country_code: 'AF', tz: 'Asia/Kabul', offset: '+04:30', dst_offset: '+04:30' },
        { country_code: 'AE', tz: 'Asia/Dubai', offset: '+04:00', dst_offset: '+04:00' },
        { country_code: 'AD', tz: 'Europe/Andorra', offset: '+01:00', dst_offset: '+02:00' },
        { country_code: '', tz: 'UTC', offset: '+00:00', dst_offset: '+00:00' }
    ];
}
/**
 * Gets the default "latest as of" of property(depends on timezone). The latest as of should not exceed to current date.
 * 
 * @param {Object} hotel Property 
 * @returns {Moment} The latest as of date of property
 */
export function getDefaultPropertyLatestAsOf(property) {
    if (!property) return dayjs().startOf('day').utc(true);

    const hotelTimezone = getTimezones().find(timezone => timezone.tz == property?.timezone);
    const latestAsOf = property?.parser_info?.code == "integrationpartner"
        ? property?.latest_as_of
        : property?.last_file_upload?.reservations?.completed_on;

    if (hotelTimezone && latestAsOf) {
        const currentHotelDate = dayjs().utcOffset(hotelTimezone.offset).startOf('day');
        const latestSummaryAsOf = dayjs.utc(latestAsOf).utcOffset(hotelTimezone.offset, true).startOf('day');
        const asOfDate = currentHotelDate.isBefore(latestSummaryAsOf, 'day')
            ? currentHotelDate: latestSummaryAsOf;
        // Base date should be UTC.
        // Change date to UTC (not convert).
        return asOfDate.utc(true);
    }
    // Timezone is unidentified. Use UTC.
    if (latestAsOf) {
        const currentHotelDate = dayjs().startOf('day').utc(true);
        const latestSummaryAsOf = dayjs.utc(latestAsOf).startOf('day');

        const asOfDate = currentHotelDate.isBefore(latestSummaryAsOf, 'day')
            ? currentHotelDate: latestSummaryAsOf;
        // Base date should be UTC.
        // Change date to UTC (not convert).
        return asOfDate.utc(true);
    }
    // No latest as of or zero data is not initialized yet. Get current date (UTC).
    return dayjs().startOf('day').utc(true);
}