import React from "react";
import { useSelector } from "react-redux"

const AuthUserContext = React.createContext(null);



const withAuthorization = condition => Component => {
    const WithAuthorizationComponent = props => {
        const permissions = useSelector(state => state.users.permissions);
        return <>{permissions[condition] ? <Component {...props} /> : null}</>;
    };
    return WithAuthorizationComponent;
};

export { AuthUserContext, withAuthorization };
