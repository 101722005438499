import React, { useEffect, createRef } from "react";

import { useSelector } from "react-redux";
import {  Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "./react-auth0-spa";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { Tutorial } from "components/Tutorial/index";
import { CSSTransition, TransitionGroup, SwitchTransition } from "react-transition-group";
import Main from "containers/Main";
import Help from "components/Help/Help";
import Navigator from "components/Nav/Nav";
// import moment from "moment";
// import "react-dates/initialize";
// import "react-dates/lib/css/_datepicker.css";
import "./assets/sass/main.scss";

import Dashboard from "components/Dashboard";
import Properties from "containers/Properties";
import Users from "containers/Users";
import PropertiesForm from "components/Forms/PropertiesForm";
import PropertyGroupForm from "components/PropertyGroupForm";
import UsersForm from "components/Forms/UsersForm";
import newUsersForm from "components/UserForm";
import Account from "containers/Account/Account";
import Admin from "containers/Admin";
import OTP from "components/MFA/OTP";

import useHelperFunction from "./store/actions/actions";
import useUsersActions from "./store/actions/usersActions";
import useProperties from "./store/actions/propertiesActions";

import dayjs from "lib/dayjs";
// import { SocketContext, socket } from './context/socket';
const App = () => {
    const state = useSelector((state) => state);
    const { getUserProfile } = useUsersActions();
    const { user, isAuthenticated } = useAuth0();
    const { toggleTutorialSection } = useHelperFunction();
    const { getProperties } = useProperties();
    const { updateUserTutorial } = useUsersActions();
    const location = useLocation();
    const navigate = useNavigate();
    const nodeRef = createRef();
    // Change locale so week starts at Monday
    dayjs.updateLocale('en', { weekStart: 0 });

    const requiresOTP = (user) => {
        const lastOTP = user?.last_otp;
        if (!user) return false;
        if (!lastOTP) return true;
        if (dayjs().diff(dayjs(lastOTP), 'days') > 30) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (!state.users.authUser && isAuthenticated) {
            getUserProfile().then(result => {
                if (result.type === 'GET_AUTH_USER_SUCCESS') {
                    const user = result.response.data.data;
                    if (requiresOTP(user)) {
                        console.log("Redirecting to OTP page...");
                        navigate(`/otp?redirect=${location.pathname}`, { replace: true });
                    }
                }
            });
            setPropertiesModal();
            async function setPropertiesModal() {
                const properties = await getProperties();
                let propertiesLatestAsOfModal = {};
                properties.response.data.data.forEach((item) => {
                    propertiesLatestAsOfModal[item.property_code] = true;
                });

                localStorage.setItem(
                    "propertiesLatestAsOfModal",
                    JSON.stringify(propertiesLatestAsOfModal)
                );
            }
        }

        if (state.users.authUser?.tutorial) {
            toggleTutorialSection({
                section: "overviewCalendar",
                payload: true,
            });
            toggleTutorialSection({
                section: "dashboard",
                payload: true,
            });
            toggleTutorialSection({
                section: "sidepanel",
                payload: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);


    // Once an authenticated user is found
    // Set a local storage value of 'userCustomFilters' that contains all the user custom filters for that user
    useEffect(() => {
        let userCustomFilters = (state.users.authUser && Array.isArray(state.users.authUser.cf))
            ? state.users.authUser.cf
            : []
        localStorage.setItem("userCustomFilters", JSON.stringify(userCustomFilters));
    }, [state.users.authUser])

    const isPropertyGroupsEnabled = state.users?.authUser?.prefs?.propertyGroupsEnabled ?? false;
    const UserForm = isPropertyGroupsEnabled ? newUsersForm : UsersForm;

    return <div id="app" className="App">
        {/* <SocketContext.Provider value={socket}> */}
        <Navigator />

        <TransitionGroup component={null}>
            <CSSTransition nodeRef={nodeRef} key={location.key} timeout={400} classNames="fade">
                <Routes location={location}>
                    <Route path="/" element={<Main ref={nodeRef} />}>
                        <Route index element={<Properties />} />
                        <Route path="users">
                            <Route index element={<Users />} />
                            <Route path="add" element={<UserForm/>} />
                            <Route path=":id/edit" element={<UserForm/>} />
                        </Route>
                        <Route path="properties">
                            <Route index element={<Properties />} />
                            <Route path="add" element={<PropertiesForm />} />
                            <Route path=":id/edit" element={<PropertiesForm />} />
                            <Route path="groups/add" element={<PropertyGroupForm />} />
                            <Route path="groups/:id/edit" element={<PropertyGroupForm />} />
                        </Route>
                        <Route path="account" element={<Account />} />
                        <Route path="admin" element={<Admin />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="otp" element={<OTP />} />
                    </Route>
                </Routes>
            </CSSTransition>
        </TransitionGroup>
        
        <ToastsContainer store={ToastsStore} />
        <Tutorial
            isNewUser={state.users.authUser?.tutorial}
            updateUserTutorial={updateUserTutorial}
        />
        <Help />
    </div>
};

export default App;
