import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { ToastsStore } from "react-toasts";
import { Modal, Select } from 'antd';
import _ from "lodash";

import { Exit } from "Icons";
import Button from "../Button/Button";
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import usePropertiesActions from "../../store/actions/propertiesActions";
import { isNullOrUndefined } from "../../utils/index";

const { Option } = Select;



const SegmentModal = ({ visible, onClose, segmentState, segmentInput, deleteModal, onDeleteModalClose }) => {
    const { getSegments, addSegment, updatePropertySegments, deletePropertySegment, addSegmentGroup } = usePropertiesActions();
    const property = useSelector(state => state.properties.property);
    const user = useSelector(state => state.users.authUser);

    const [segments, setSegments] = useState([]);
    const [addSegmentModal, setAddSegmentModal] = useState(false);
    const [addSegmentGroupModal, setAddSegmentGroupModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (segmentState === 'edit') {
            setInputs({
                name: segmentInput.local.name,
                code: segmentInput.local.code,
                global: segmentInput.global,
                selected_segment_group: segmentInput.local.gc ? segmentInput.local.gc : '',
                description: segmentInput.local.description,
            })
        } else {
            clearInputs();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, deleteModal])

    useEffect(() => {
        if (!addSegmentModal && !isNullOrUndefined(property)) {
            getSegments().then((response) => {
                setSegments(response.data.data)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addSegmentModal])

    const [deleteInput, setDeleteInput] = useState('');

    const [inputs, setInputs] = useState({
        name: '',
        code: '',
        global: '',
        selected_segment_group: '',
        description: '',
    })

    const [segmentInputs, setSegmentInputs] = useState({
        segment: '',
        segment_code: ''
    })

    const [segmentGroupInputs, setSegmentGroupInputs] = useState({
        segment_group: '',
        segment_group_code: ''
    })

    const [errors, setErrors] = useState({
        name: '',
        code: '',
        global: '',
        description: '',
        segment: '',
        segment_code: '',
        delete: '',
        segment_group: '',
        segment_group_code: '',
        selected_segment_group: '',
    })

    const handleAddSegmentChange = (e) => {
        const newSegmentInputs = { ...segmentInputs };

        const hasErrors = validate(e.target);
        if (hasErrors) return;

        newSegmentInputs[e.target.name] = e.target.value;

        setSegmentInputs(newSegmentInputs);

    }

    const handleAddSegmentGroupChange = (e) => {
        const newSegmentInputs = { ...segmentGroupInputs };

        const hasErrors = validate(e.target);
        if (hasErrors) return;

        newSegmentInputs[e.target.name] = e.target.value;

        setSegmentGroupInputs(newSegmentInputs);

    }


    const handleInputChange = (e, name) => {
        const newInputs = { ...inputs };

        const hasErrors = e.target ? validate(e.target) : validate({ name: 'global', value: 'e' });

        if (hasErrors) return;

        if (typeof e !== 'string') {
            newInputs[e.target.name] = e.target.value;
        } else {
            if (e === 'addSegment') {
                setAddSegmentModal(true);
                return;
            } else if (e === 'addSegmentGroup') {
                setAddSegmentGroupModal(true);
                return;
            }
            newInputs[name] = e;
        }

        setInputs(newInputs);
    }

    const handleSubmitForm = (e, form) => {
        e.preventDefault();
        setSubmitting(true);

        let hasErrors = false;
        const newErrors = {};

        if (form === 'segment') {
            Object.keys(segmentInputs).forEach((input) => {
                newErrors[input] = ''; // Reset errors in specific modal then find the errors again.
                if (segmentInputs[input] === '') newErrors[input] = 'This field is required';
            })

            Object.keys(newErrors).forEach((input) => {
                if (newErrors[input].length > 0) hasErrors = true;
            })

            if (!hasErrors) {
                addSegment({
                    code: segmentInputs.segment_code,
                    name: segmentInputs.segment
                }).then((response) => {
                    setInputs({ ...inputs, global: response.data.data.segment_code });
                    setAddSegmentModal(false);
                    setSegmentInputs({
                        segment: '',
                        segment_code: ''
                    });
                    setSubmitting(false);
                    ToastsStore.success("Successfully added.");
                }).catch((err) => {
                    setSubmitting(false);
                    ToastsStore.error(err.response.data.message);
                })
            } else {
                setSubmitting(false);
            }

            setErrors({ ...errors, ...newErrors });

        } else if (form === 'segmentGroup') {

            Object.keys(segmentGroupInputs).forEach((input) => {
                newErrors[input] = ''; // Reset errors in specific modal then find the errors again.
                if (segmentGroupInputs[input] === '') newErrors[input] = 'This field is required';
            })

            Object.keys(newErrors).forEach((input) => {
                if (newErrors[input].length > 0) hasErrors = true;
            })

            if (!hasErrors) {
                addSegmentGroup({
                    code: segmentGroupInputs.segment_group_code,
                    name: segmentGroupInputs.segment_group
                }, property._id).then((response) => {
                    setInputs({ ...inputs, selected_segment_group: segmentGroupInputs.segment_group_code });
                    setAddSegmentGroupModal(false);
                    setSegmentGroupInputs({
                        segment_group: '',
                        segment_group_code: ''
                    });
                    setSubmitting(false);
                    ToastsStore.success("Successfully added.");
                }).catch((err) => {
                    setSubmitting(false);
                    ToastsStore.error(err.response.data.message);
                })
            } else {
                setSubmitting(false);
            }

            setErrors({ ...errors, ...newErrors });

        } else {

            Object.keys(inputs).forEach((input) => {
                newErrors[input] = ''; // Reset errors in specific modal then find the errors again.
                if (inputs[input] === '' && input !== 'global') newErrors[input] = 'This field is required';
            })
            setErrors(newErrors);

            Object.keys(newErrors).forEach((input) => {
                if (!hasErrors) {
                    if (newErrors[input].length > 0) hasErrors = true;
                }
            })

            const segmentGroupIndex = property.segment_groups.findIndex(d => d.gc === inputs.selected_segment_group)
            const segmentOldGroupIndex = !_.isEmpty(segmentInput) &&
                property.segment_groups.findIndex(d => d.gc === segmentInput.local.gc)
            if (!hasErrors) {
                setLoading(true);
                updatePropertySegments(inputs, property._id, segmentInput, segmentGroupIndex, segmentOldGroupIndex)
                    .then((response) => {
                        setLoading(false)
                        onClose();
                        clearInputs();
                        setSubmitting(false);
                        ToastsStore.success("Successfully updated.");
                    }).catch((err) => {
                        setSubmitting(false);
                        ToastsStore.error(err.response.data.message);
                    })
            } else {
                setSubmitting(false);
            }

            setErrors({ ...errors, ...newErrors });

        }
    }

    const handleDeleteSegment = () => {
        const hasErrors = validate({ name: 'delete', value: deleteInput });

        if (hasErrors) return;

        if (!hasErrors) {
            setLoading(true)
            deletePropertySegment(property._id, segmentInput.local.code).then((response) => {
                setLoading(false)
                ToastsStore.success("Successfully deleted.");
                onDeleteModalClose();
                setDeleteInput('');
            }).catch(() => {
                ToastsStore.error('Something went wrong');
            })
        }
    }

    return <div>
        <Modal
            open={visible} width={500} maskClosable={false} footer={null}
            closeIcon={<Exit width="25px" height="25px" className="pointer" />}
            onCancel={() => {
                onClose(false)
                clearInputs();
            }}
        >
            <div className="rms-modal-content">
                {addSegmentModal && <div className="rms-mask"></div>}
                <div className="rms-modal-header">
                    {(user.type === 'ope_admin' || user.type === 'hotel_admin')
                        ? <h3>{(segmentState === 'add') ? 'Add Segment' : 'Edit Segment'}</h3>
                        : <h3>Segment Details</h3>
                    }
                </div>
                <form onSubmit={(e) => handleSubmitForm(e, 'property')} className="light-form">
                    <div className="single-row-input">
                        <div style={{ flexGrow: 2, marginRight: '8px' }} className="light-input-container">
                            <div className="light-label">
                                Segment Name
                            </div>
                            <input className="light-input" disabled={user.type === 'hotel_user'}
                                name="name" type="text" value={inputs.name}
                                onChange={handleInputChange} onBlur={handleInputChange} />
                            {errors.name.length > 0 && <div className="light-input-error">
                                {errors.name}
                            </div>}
                        </div>

                        <div className="light-input-container">
                            <div className="light-label">
                                Segment Code
                            </div>
                            <input className="light-input disabled" disabled={user.type === 'hotel_user'}
                                name="code" type="text" value={inputs.code}
                                onChange={handleInputChange} onBlur={handleInputChange} />
                            {errors.code.length > 0 && <div className="light-input-error">
                                {errors.code}
                            </div>}
                        </div>
                    </div>

                    <div className="light-input-container">
                        <div className="light-label">
                            Map to rev&you segments
                        </div>

                        <Select className="light-select" disabled={user.type === 'hotel_user'}
                            value={inputs.global} onChange={(value) => handleInputChange(value, 'global')}
                        >
                            {segments.length > 0 && segments.map(segment =>
                                <Option key={segment._id} className="light-option"
                                    value={segment._id}
                                >
                                    {segment.name} ({segment._id})
                                </Option>
                            )}
                            {/* <Option className="add-segment-option" value="addSegment">
                                Add Segment
                            </Option> */}
                        </Select>

                        {errors.global.length > 0 && <div className="light-input-error">
                            {errors.global}
                        </div>}
                    </div>

                    <div className="light-input-container">
                        <div className="light-label">
                            Add to segment group
                        </div>

                        <Select className="light-select" disabled={user.type === 'hotel_user'}
                            value={inputs.selected_segment_group}
                            onChange={(value) => handleInputChange(value, 'selected_segment_group')}
                        >
                            {!isNullOrUndefined(property) && property.segment_groups.length > 0
                                && property.segment_groups.map((item) => {
                                    if (!isNullOrUndefined(item) && !isNullOrUndefined(item.gc)) {
                                        return <Option key={item.gc} className="light-option" value={item.gc}>
                                            {item.g} ({item.gc})
                                        </Option>
                                    }
                                })
                            }
                            <Option className="add-segment-option" value="addSegmentGroup">
                                Add Segment Group
                            </Option>
                        </Select>

                        {errors.selected_segment_group.length > 0 && <div className="light-input-error">
                            {errors.selected_segment_group}
                        </div>}
                    </div>

                    <div className="light-input-container">
                        <div className="light-label">
                            Description
                        </div>

                        <textarea className="light-textarea" disabled={user.type === 'hotel_user'}
                            rows="4" name="description" value={inputs.description}
                            onChange={handleInputChange} onBlur={handleInputChange} />

                        {errors.description.length > 0 && <div className="light-input-error">
                            {errors.description}
                        </div>}
                    </div>
                    <div>
                        {!(user.type === 'hotel_user') && <div className="light-form-buttons">
                            <Button btnType="submit" type="primary" style={{ marginRight: '8px' }}
                                disabled={submitting} onClick={(e) => handleSubmitForm(e, 'property')}
                            >
                                {segmentState === 'add' ? 'Add' : 'Edit'}
                            </Button>

                            <Button type="bg-light" onClick={() => {
                                onClose(false)
                                clearInputs();
                            }}>
                                Cancel
                            </Button>
                        </div>}
                    </div>
                </form>

                {loading && <>
                    <div style={{
                        width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.1)',
                        position: 'absolute', top: 0, left: 0, zIndex: 10
                    }} />
                    <LoadingSpinner />
                </>}
            </div>
        </Modal>


        <Modal open={addSegmentModal} width={500} footer={null}
            closeIcon={<Exit width="25px" height="25px" className="pointer" />}
            onCancel={() => setAddSegmentModal(false)}
        >
            <div className="rms-modal-content">
                <div className="rms-modal-header">
                    <h3>Add New Segment</h3>
                </div>

                <form onSubmit={(e) => handleSubmitForm(e, 'segment')} className="light-form">
                    <div className="light-input-container">
                        <div className="light-label">
                            Rev&you Segment Code
                        </div>

                        <input className="light-input"
                            name="segment_code" type="text" value={segmentInputs.segment_code}
                            onChange={handleAddSegmentChange} onBlur={handleAddSegmentChange} />

                        {errors.segment_code.length > 0 && <div className="light-input-error">
                            {errors.segment_code}
                        </div>}
                    </div>

                    <div className="light-input-container">
                        <div className="light-label">
                            Rev&you Segment Name
                        </div>

                        <input className="light-input"
                            name="segment" type="text" value={segmentInputs.segment}
                            onChange={handleAddSegmentChange} onBlur={handleAddSegmentChange} />

                        {errors.segment.length > 0 && <div className="light-input-error">
                            {errors.segment}
                        </div>}
                    </div>

                    <div className="light-form-buttons">
                        <Button btnType="submit" type="primary" style={{ marginRight: '8px' }}
                            disabled={submitting}
                        >
                            ADD
                        </Button>
                        <Button type="bg-light" onClick={() => setAddSegmentModal(false)}>
                            Cancel
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>


        <Modal open={addSegmentGroupModal}
            closeIcon={<Exit width="25px" height="25px" className="pointer" />}
            onCancel={() =>
                setAddSegmentGroupModal(false)
            }
            width={500}
            footer={null}>
            <div className="rms-modal-content">
                <div className="rms-modal-header">
                    <h3>Add New Segment Group</h3>
                </div>

                <form onSubmit={(e) => handleSubmitForm(e, 'segmentGroup')} className="light-form">
                    <div className="light-input-container">
                        <div className="light-label">
                            Segment Group Code
                        </div>

                        <input className="light-input"
                            name="segment_group_code" type="text" value={segmentGroupInputs.segment_group_code}
                            onChange={handleAddSegmentGroupChange} onBlur={handleAddSegmentGroupChange} />

                        {errors.segment_group_code.length > 0 && <div className="light-input-error">
                            {errors.segment_group_code}
                        </div>}
                    </div>

                    <div className="light-input-container">
                        <div className="light-label">
                            Segment Group Name
                        </div>

                        <input className="light-input"
                            name="segment_group" type="text" value={segmentGroupInputs.segment_group}
                            onChange={handleAddSegmentGroupChange} onBlur={handleAddSegmentGroupChange} />

                        {errors.segment_group.length > 0 && <div className="light-input-error">
                            {errors.segment_group}
                        </div>}
                    </div>

                    <div className="light-form-buttons">
                        <Button btnType="submit" type="primary" style={{ marginRight: '8px' }}
                            disabled={submitting}
                        >
                            ADD
                        </Button>
                        <Button type="bg-light" onClick={() => setAddSegmentGroupModal(false)}>
                            Cancel
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>


        <Modal open={deleteModal} width={500} footer={null}
            closeIcon={<Exit width="25px" height="25px" className="pointer" />}
            onCancel={() => onDeleteModalClose()}
        >
            <div className="rms-modal-content">
                <div className="rms-modal-header">
                    <h3>Delete Segment Mapping</h3>
                </div>

                <div className="light-form delete-segment">
                    <p>Mapping for <span className="segment-span">
                        {segmentInput.local?.code}
                    </span> will be removed from this property and all it's corresponding data will become unsegmented.
                    </p>
                    <p>Are you sure you want to delete mapping for this segment?</p>

                    <div className="light-input-container">
                        <p>Please enter the segment code to delete</p>

                        <input className="light-input"
                            name="delete" type="text" value={deleteInput}
                            onChange={(e) => setDeleteInput(e.target.value)} onBlur={handleAddSegmentChange} />

                        {errors.delete.length > 0 && <div className="light-input-error">
                            {errors.delete}
                        </div>}
                    </div>

                    <div className="light-form-buttons">
                        <Button type="primary" style={{ marginRight: '8px' }}
                            onClick={handleDeleteSegment}
                        >
                            DELETE
                        </Button>
                        <Button type="bg-light" onClick={() => onDeleteModalClose()}>
                            Cancel
                        </Button>
                    </div>
                </div>
                {loading && <>
                    <div style={{
                        width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.1)',
                        position: 'absolute', top: 0, left: 0, zIndex: 10
                    }} />
                    <LoadingSpinner />
                </>}
            </div>
        </Modal>
    </div>


    function validate(input) {

        const newErrors = { ...errors };
        let hasErrors = false;
        let existing = false;
        let continueInput = true;
        let i = 0;

        if (input === undefined) return hasErrors;

        switch (input.name) {
            case 'name':
                newErrors.name = input.value.length > 50 ? 'Reached max characters' : '';
                continueInput = !(input.value.length > 50);
                newErrors.name = input.value.length === 0 ? 'This field is required' : '';
                for (i; i < property.segments.length; i++) {
                    if (property.segments[i].local.name.toUpperCase() === input.value.toUpperCase()) {
                        existing = true;
                        break;
                    }
                }
                if (existing && segmentInput.local?.name !== input.value) newErrors.name = 'Segment name is already used';
                hasErrors = newErrors.name.length > 0;
                break;
            case 'segment':
                newErrors.segment = input.value.length > 50 ? 'Reached max characters' : '';
                newErrors.segment = input.value.length === 0 ? 'This field is required' : '';
                continueInput = !(input.value.length > 50);
                for (i; i < segments.length; i++) {
                    const segment = segments[i];
                    const segmentName = segment && segment.segment;
                    if (segmentName && segmentName.toUpperCase() === input.value.toUpperCase()) {
                        existing = true;
                        break;
                    }
                }
                if (existing) newErrors.segment = 'Segment name is already used';

                hasErrors = newErrors.segment.length > 0;
                break;
            case 'code':
                newErrors.code = input.value.length > 10 ? 'Reached max characters' : '';
                newErrors.code = input.value.length === 0 ? 'This field is required' : '';
                continueInput = !(input.value.length > 10);
                hasErrors = newErrors.code.length > 0;
                for (i; i < property.segments.length; i++) {
                    if (property.segments[i].local.code.toUpperCase() === input.value.toUpperCase()) {
                        existing = true;
                        break;
                    }
                }
                if (existing && segmentInput.local?.code !== input.value) newErrors.code = 'Segment code is already used';
                break;
            case 'segment_code':
                newErrors.segment_code = input.value.length > 10 ? 'Reached max characters' : '';
                newErrors.segment_code = input.value.length === 0 ? 'This field is required' : '';
                continueInput = !(input.value.length > 10);
                for (i; i < segments.length; i++) {
                    const segment = segments[i];
                    const segmentCode = segment && segment.segment_code;
                    if (segmentCode && segmentCode.toUpperCase() === input.value.toUpperCase()) {
                        existing = true;
                        break;
                    }
                }
                if (existing) newErrors.segment_code = 'Segment code is already used';
                hasErrors = newErrors.segment_code.length > 0;
                break;
            case 'description':
                newErrors.description = input.value.length > 150 ? 'Reached max characters' : '';
                newErrors.description = input.value.length === 0 ? 'This field is required' : '';
                continueInput = !(input.value.length > 150);
                hasErrors = newErrors.description.length > 0;
                break;
            case 'segment_group_code':
                newErrors.segment_group_code = input.value.length > 10 ? 'Reached max characters' : '';
                newErrors.segment_group_code = input.value.length === 0 ? 'This field is required' : '';
                continueInput = !(input.value.length > 10);
                hasErrors = newErrors.segment_group_code.length > 0;
                break;
            case 'segment_group':
                newErrors.segment_group = input.value.length > 50 ? 'Reached max characters' : '';
                newErrors.segment_group = input.value.length === 0 ? 'This field is required' : '';
                continueInput = !(input.value.length > 50);
                hasErrors = newErrors.segment_group.length > 0;
                break;
            case 'delete':
                newErrors.delete = input.value !== segmentInput.local.code ? 'Incorrect segment code' : '';
                hasErrors = newErrors.delete.length > 0;
                continueInput = false;
                break
            default:
                break;
        }

        setErrors(newErrors);
        return hasErrors && !continueInput;

    }

    function clearInputs() {
        setInputs({
            name: '',
            code: '',
            global: '',
            selected_segment_group: '',
            description: ''
        })

        setDeleteInput('');

        setErrors({
            name: '',
            code: '',
            global: '',
            description: '',
            segment: '',
            segment_code: '',
            segment_group: '',
            segment_group_code: '',
            delete: '',
            selected_segment_group: '',
        })
    }

}

export default SegmentModal;
