import constants from "../../constants";
import { useDispatch } from "react-redux";
import { useAuth0 } from "../../react-auth0-spa";
import axios from "axios";

const useUsersActions = () => {
    const dispatch = useDispatch();
    const { getTokenSilently } = useAuth0();

    const getUserProfile = async () => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.GET_AUTH_USER_REQUEST,
                constants.GET_AUTH_USER_SUCCESS,
                constants.GET_AUTH_USER_FAILURE,
            ],
            callAPI: () =>
                axios.get(`${constants.BASE_URL_2}/users/getprofile`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    };

    const getUsers = async (name = "", sort = "", page = 1, limit = 5) => {
        const token = await getTokenSilently();
        const params = { name, page, sort, limit };
        const result = dispatch({
            types: [
                constants.GET_USERS_REQUEST,
                constants.GET_USERS_SUCCESS,
                constants.GET_USERS_FAILURE,
            ],
            callAPI: () =>
                axios.get(`${constants.BASE_URL_2}/users`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params,
                }),
            payload: {
                searchTerm: name,
            },
        });
        return result;
    };

    const getUser = async (id) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.GET_USER_REQUEST,
                constants.GET_USER_SUCCESS,
                constants.GET_USER_FAILURE,
            ],
            callAPI: () =>
                axios.get(`${constants.BASE_URL_2}/users/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });

        return result;
    };

    const addUser = async (data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.CREATE_USER_REQUEST,
                constants.CREATE_USER_SUCCESS,
                constants.CREATE_USER_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/users`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    };

    const updateUser = async (id, data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.UPDATE_USER_REQUEST,
                constants.UPDATE_USER_SUCCESS,
                constants.UPDATE_USER_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/users/${id}`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    };

    const updateUserProperty = async (id, data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.UPDATE_USER_PROPERTY_REQUEST,
                constants.UPDATE_USER_PROPERTY_SUCCESS,
                constants.UPDATE_USER_PROPERTY_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/users/${id}`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    };

    const updateUserProfile = async (data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.UPDATE_USER_PROFILE_REQUEST,
                constants.UPDATE_USER_PROFILE_SUCCESS,
                constants.UPDATE_USER_PROFILE_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/users/updateprofile`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
        });

        return result;
    };

    const updateUserTutorial = async (data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            type: constants.UPDATE_USER_TUTORIAL,
            action: await axios.post(
                `${constants.BASE_URL_2}/users/updateprofile`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            ),
        });

        return result;
    };

    const sendResetPasswordEmail = async (email) => {
        const token = await getTokenSilently();

        const data = {
            client_id: constants.AUTH0_APP_CLIENT_ID,
            email: email,
            connection: constants.AUTH0_DB_CONNECTION,
        };

        const result = dispatch({
            types: [
                constants.SEND_RESET_PASSWORD_EMAIL_REQUEST,
                constants.SEND_RESET_PASSWORD_EMAIL_SUCCESS,
                constants.SEND_RESET_PASSWORD_EMAIL_FAILURE,
            ],
            callAPI: () =>
                axios.post(
                    `${constants.AUTH0_API}/dbconnections/change_password`,
                    data,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                ),
        });

        return result;
    };

    const clearUser = () => {
        dispatch({ type: "CLEAR_USER" });
    };


    // Function to retrieve user custom filters
    const retrieveUserCustomFilters = async (data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.GET_USER_CUSTOM_FILTERS_REQUEST,
                constants.GET_USER_CUSTOM_FILTERS_SUCCESS,
                constants.GET_USER_CUSTOM_FILTERS_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/users/getcustomfilters`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
        });

        return result;
    };


    // Function to add or remove user custom filters
    const updateUserCustomFilters = async (data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.UPDATE_USER_CUSTOM_FILTERS_REQUEST,
                constants.UPDATE_USER_CUSTOM_FILTERS_SUCCESS,
                constants.UPDATE_USER_CUSTOM_FILTERS_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/users/updatecustomfilters`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
        });

        return result;
    };



    return {
        getUsers,
        getUser,
        addUser,
        getUserProfile,
        updateUser,
        updateUserProperty,
        updateUserProfile,
        sendResetPasswordEmail,
        clearUser,
        updateUserTutorial,
        retrieveUserCustomFilters,
        updateUserCustomFilters,
    };
};
export default useUsersActions;
