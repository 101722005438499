import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from 'react-auth0-spa';
import { useSelector, useDispatch } from 'react-redux';

import useProperties from 'store/actions/propertiesActions';
import useHelperFunctions from 'store/actions/actions';
import usePropertyGroups from 'store/actions/propertyGroupActions';
import Logo from '../../assets/images/logo-dark.png';
import { isNullOrUndefined } from 'utils';
import useNav from './useNav';
import ProfileMenu from './ProfileMenu';
import PropertyMenu from './PropertyMenu';
import PropertyGroupMenu from './PropertyGroupMenu';
import constants from 'constants.js';

const Nav = () => {
    const dispatch = useDispatch();
    const state = useSelector((state) => ({
        main: state.mainReducer,
        users: state.users,
        dashboard: state.dashboard,
        properties: state.properties,
        propertyGroups: state.propertyGroups
    }));
    const location = useLocation();
    const navigate = useNavigate();
    const { isAuthenticated, logout } = useAuth0();
    const { changeActiveHotel } = useNav();
    const { getProperties } = useProperties();
    const { loadPropertyGroupsState } = usePropertyGroups();
    const { setShowUploadModal, toggleChartModal } = useHelperFunctions();

    const [isPropertyGroup, setIsPropertyGroup] = useState(false);
    const [visible, setVisible] = useState(false);

    const showPropertyMenus = () => {
        return isAuthenticated &&
            location.pathname !== '/' &&
            location.pathname !== '/v2' &&
            !location.pathname.includes('/users') &&
            !location.pathname.includes('/properties/add') &&
            !location.pathname.includes('/properties/groups/add') &&
            !location.pathname.includes('/account') &&
            !location.pathname.includes('/admin') &&
            !location.pathname.includes('/otp');
    }

    const handleLogout = () => {
        logout({ returnTo: process.env.REACT_APP_LOGOUT_RETURN_URL });
        localStorage.removeItem('authUser');
        localStorage.removeItem('activeHotel');
        localStorage.removeItem('managePropertyTab');
        localStorage.removeItem('userId');
    };

    useEffect(() => {

        const isOnPropertyGroup = location.pathname.includes('/groups');
        setIsPropertyGroup(isOnPropertyGroup);

        if (location.pathname !== '/' && !location.pathname.includes('/account')) {
            if (isAuthenticated) {
                if (isOnPropertyGroup) {
                    loadPropertyGroupsState('', 'name', 1);
                } else {
                    getProperties('', 'updatedAt', 1);
                }
            }
        }
    }, [isAuthenticated, state.users.authUser, location.pathname]);

    const handleLogoRedirect = () => {
        let userPrefs = state.users.authUser.prefs;
        let hasRedirectUrl = !isNullOrUndefined(userPrefs) && userPrefs.logoRedirectUrl !== '';
        let urlPath = state.users.authUser.prefs.logoRedirectUrl.split('/')[3];
        if (hasRedirectUrl) 
            navigate(urlPath ? urlPath : '/') // navigate(urlPath ? urlPath : '/');
    }

    return (
        <div className='navbar is-fixed-top'>
            <div className='navbar-content-wrapper'>
                <div className='logo' onClick={() => handleLogoRedirect()}>
                    <img alt='Rev & You Logo' src={Logo} />
                </div>

                <div className='nav-items-wrapper'>
                    {isAuthenticated && (
                        <div className='nav-item' style={{ position: 'relative' }}>
                            <ProfileMenu
                                showSpot={state.main.tutorial.avatar.showSpot}
                                users={state.users}
                                eventHandler={(e) => {
                                    switch (e.action) {
                                        case 'logout':
                                            handleLogout();
                                            break;
                                    }
                                }}
                            />
                        </div>
                    )}

                    <div className='nav-item'>
                        <span
                            className='hotelMenuTutorial'
                            style={{ position: 'relative' }}
                        >
                            {showPropertyMenus() && (
                                isPropertyGroup || (state.main.isGroup ?? false)
                                    ? <PropertyGroupMenu
                                        showSpot={state.main.tutorial.hotelMenu.showSpot}
                                        selectedPropertyGroup={state.propertyGroups.selection.data ?? {}}
                                        propertyGroups={state.propertyGroups.list.data ?? []}
                                        visible={visible}
                                        eventHandler={(e) => {
                                            switch (e.action) {
                                                case 'toggle_dropdown': 
                                                    setVisible(e.value); 
                                                    break;
                                                case 'go_to_property_dashboard': case 'manage_property_group':
                                                    setVisible(false); 
                                                    break;
                                                case 'select_property_group':
                                                    state.dashboard.source.cancel();
                                                    dispatch({ type: constants.SET_SOURCE })
                                                    if (location.pathname.includes('properties/groups'))
                                                        navigate(`/properties/groups/${e.propertyGroup._id}/edit`);

                                                    changeActiveHotel({ ...e.propertyGroup, group: state.main.isGroup });
                                                    dispatch({
                                                        type: constants.UPDATE_VISUALS_SEGMENTS_DATA,
                                                        value: { current: null, cache: [] }
                                                    })
                                                    dispatch({
                                                        type: constants.UPDATE_VISUALS_ASOFS_DATA,
                                                        value: { current: null, cache: [] }
                                                    });
                                                    dispatch({
                                                        type: constants.SHOW_VISUALS,
                                                        value: false
                                                    });
                                                    dispatch({
                                                        type: constants.SHOW_ALGORITHM,
                                                        value: false
                                                    });
                                                    toggleChartModal({ show: false });
                                                    setVisible(false);
                                                    break;
                                            }
                                        }}
                                    />
                                    : <PropertyMenu
                                        showSpot={state.main.tutorial.hotelMenu.showSpot}
                                        selectedProperty={state.main.property}
                                        properties={state.properties.properties}
                                        visible={visible}
                                        eventHandler={(e) => {
                                            switch (e.action) {
                                                case 'toggle_dropdown': 
                                                    setVisible(e.value); 
                                                    break;
                                                case 'go_to_property_dashboard': case 'manage_property':
                                                    setVisible(false); 
                                                    break;
                                                case 'show_upload_modal':
                                                    setShowUploadModal({
                                                        id: e.property._id,
                                                        name: e.property.name,
                                                        parser_code: e.property.parser_info?.code,
                                                        visible: true,
                                                        code: e.property.property_code
                                                    });
                                                    setVisible(false);
                                                    break;
                                                case 'select_property':
                                                    state.dashboard.source.cancel();
                                                    dispatch({ type: constants.SET_SOURCE });

                                                    if (location.pathname.includes('properties'))
                                                        navigate(`/properties/${e.property._id}/edit`);

                                                    changeActiveHotel(e.property);
                                                    dispatch({
                                                        type: constants.UPDATE_VISUALS_SEGMENTS_DATA,
                                                        value: { current: null, cache: [] }
                                                    })
                                                    dispatch({
                                                        type: constants.UPDATE_VISUALS_ASOFS_DATA,
                                                        value: { current: null, cache: [] }
                                                    });
                                                    dispatch({
                                                        type: constants.SHOW_VISUALS,
                                                        value: false
                                                    });
                                                    dispatch({
                                                        type: constants.SHOW_ALGORITHM,
                                                        value: false
                                                    });
                                                    toggleChartModal({ show: false });
                                                    setVisible(false);
                                                    break;
                                            }
                                        }}
                                    />

                            )}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Nav;
