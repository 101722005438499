import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { Question } from "Icons";
import useHelperFunctions from "../../store/actions/actions";

const Help = () => {
    const location = useLocation();
    const { toggleTutorialSection } = useHelperFunctions();
    const state = useSelector((state) => state.mainReducer);
    const node = useRef(null);
    const { tutorial, showSidebar } = state;
    const [showMenu, setShowMenu] = useState(false);
    const [isDashboardShowed, setIsDashboardShowed] = useState(false);
    const [isOverviewShowed, setIsOverviewShowed] = useState(false);
    const [isSidePanelShowed, setIsSidePanelShowed] = useState(false);

    const handleSectionToggle = (section, show) => {
        toggleTutorialSection({
            section: section,
            payload: show,
        });
        setShowMenu(false);
    };

    useEffect(() => {
        let foundDashboard = false;
        let foundCalendar = false;
        let foundSidepanel = false;
        Object.keys(tutorial).forEach((item) => {
            if (tutorial[item].showSpot) {
                if (tutorial[item].section === "dashboard") foundDashboard = true;
                if (tutorial[item].section === "overviewCalendar") foundCalendar = true;
                if (tutorial[item].section === "sidepanel") foundSidepanel = true;
            }
        });

        setIsDashboardShowed(foundDashboard);
        setIsOverviewShowed(foundCalendar);
        setIsSidePanelShowed(foundSidepanel);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, showMenu]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (node.current && !node.current.contains(event.target)) {
                setShowMenu(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [node, showMenu]);

    return (
        <div className="help">
            <Question
                height="32px"
                width="32px"
                onClick={() => {
                    setShowMenu(true);
                }}
            />
            <CSSTransition nodeRef={node} in={showMenu} timeout={300} unmountOnExit>
                <ul className="help-menu" ref={node}>
                    {location.pathname.includes("dashboard") ? (
                        <>
                            {showSidebar &&
                                (isSidePanelShowed ? (
                                    <li onClick={() => handleSectionToggle("sidepanel", false)}>
                                        Hide sidepanel tutorial
                                    </li>
                                ) : (
                                        <li onClick={() => handleSectionToggle("sidepanel", true)}>
                                            Show sidepanel tutorial
                                        </li>
                                    ))}

                            {isOverviewShowed ? (
                                <li
                                    onClick={() => handleSectionToggle("overviewCalendar", false)}
                                >
                                    Hide calendar tutorial
                                </li>
                            ) : (
                                    <li
                                        onClick={() => handleSectionToggle("overviewCalendar", true)}
                                    >
                                        Show calendar tutorial
                                    </li>
                                )}
                        </>
                    ) : isDashboardShowed ? (
                        <li onClick={() => handleSectionToggle("dashboard", false)}>
                            Hide tutorial
                        </li>
                    ) : (
                                <li onClick={() => handleSectionToggle("dashboard", true)}>
                                    Show tutorial
                                </li>
                            )}
                </ul>
            </CSSTransition>
        </div>
    );
};

export default Help;
