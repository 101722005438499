import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'lib/dayjs';
import Button from 'components/Button/Button';
import useOTPActions from 'store/actions/otpActions';
import useUsersActions from 'store/actions/usersActions';
import Dog from 'assets/images/rev-dog-logo.png';

const OTP = () => {
    const state = useSelector(state => state);
    const navigate = useNavigate();
    const { sendOTP, verifyOTP } = useOTPActions();
    const { getUserProfile } = useUsersActions();
    const [searchParams] = useSearchParams();
    const [status, setStatus] = useState('SEND_OTP');

    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    
    const holdVerify = useMemo(() => {
        if (code.length !== 6) return true;
        if (status === 'SEND_OTP') return true;
        return false;
    }, [code, status]);
    
    const redirectPath = searchParams.get('redirect');

    const OTPPass = () => {
        if (redirectPath && !redirectPath.includes('otp')) {
            console.log(`Redirecting to new url: ${redirectPath}`)
            navigate(redirectPath, { replace: true });
        } else {
            console.log(`Don't know where to go... Redirecting to property list`)
            navigate('/', { replace: true });
        }
    }

    useEffect(() => {

        const requiresOTP = (user) => {
            const lastOTP = user?.last_otp;
            if (!user) return false;
            if (!lastOTP) return true;
            if (dayjs().diff(dayjs(lastOTP), 'days') > 30) {
                return true;
            }
            return false;
        }

        if (requiresOTP(state.users.authUser)) {
            console.log('Sending OTP...');
            sendOTP().then(result => {
                setTimeout(() => setStatus('VERIFY_OTP'), 1000);
            }).catch(result => {
                const response = result.response;
                switch(response.status) {
                    case 404: setError("Your session might be expired. Please refresh the page."); break;
                    case 409: break;
                    default: setError(response.data.message);
                }
                setStatus('VERIFY_OTP');
            });
        } else {
            // OTP not needed.
            OTPPass();
        }
    }, []);

    useEffect(() => {
        if (status === 'SUCCESS') {
            // Reload user info. This important to refresh last otp of user.
            getUserProfile().then((result) => {
                if (result.type === 'GET_AUTH_USER_SUCCESS') {
                    setTimeout(() => OTPPass(), 1000);
                } else {
                    setError('An error occured while fetching user data.');
                }
            }).catch((error) => {
                console.error(error);
                setError('An error occured while fetching user data.');
            });
        }
        setCode('');
    }, [status])

    const handleSendOTP = () => {
        // console.log(navigator.userAgent)
        console.log('Sending OTP...');
        setError('');
        setStatus('SEND_OTP');
        sendOTP().then(result => {
            console.log(result);
            setTimeout(() => setStatus('VERIFY_OTP'), 1000);
        }).catch(result => {
            const response = result.response;
            switch(response.status) {
                case 404: setError("Your session might be expired. Please refresh the page."); break;
                case 429: setError("Please wait at least 1 minute to request another OTP."); break;
                default: setError(response.data.message);
            }
            setStatus('NEED_NEW_OTP');
        });
    }

    const handleVerifyOTP = () => {
        console.log("Verifying OTP...");
        setError('');
        verifyOTP(code).then(result => {
            console.log(result);
            setStatus('SUCCESS');
        }).catch(result => {
            const response = result.response;
            switch(response.status) {
                case 400: setError("Unable to process request. Please try again later."); break;
                case 401: setError("Incorrect OTP! Please try again."); break;
                case 404: setError("OTP expired. Please request a new OTP."); break;
                default: setError(response.data.message);
            }
        });
    }

    const renderDogMessage = () => {
        switch(status) {
            case 'NEED_NEW_OTP': return 'Please check your email';
            case 'SEND_OTP': return 'Sending OTP to your Email';
            case 'VERIFY_OTP': return 'Please Log-in your OTP';
            case 'SUCCESS': return 'It is good to see you again!';
            default: return '...';
        }
    }

    const handleCodeChange = (e) => {
        setCode(e.target.value.substring(0, 6));
    }

    return <div className='main-otp' style={{ display: 'flex', flexFlow: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexFlow: 'column', height: 'auto', width: '185px', alignItems: 'center', gap: '15px' }}>
            <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', position: 'relative', width: '200px', height: '265px', overflow: 'hidden' }}>
                <span style={{ position: 'absolute', width: '125px', textAlign: 'center', lineHeight: '21px', textTransform: 'uppercase', left: '44px' }}>
                    {renderDogMessage()}
                </span>
                <img src={Dog} style={{ width: '100%' }} />
            </div>
            <input type='text' onChange={handleCodeChange}  placeholder='ENTER OTP HERE' value={code} style={{ 
                borderRadius: '5px', padding: '6px', color: '#231f20', textAlign: 'center', fontSize: '18px', fontWeight: 600, border: 'none', width: '100%'
            }} />
            <Button type={holdVerify? 'disabled': 'primary'} style={{ width: '100%' }} onClick={() => handleVerifyOTP()} disabled={holdVerify}>Verify OTP</Button>
            <Button type={status === 'SUCCESS'? 'disabled': 'primary'} style={{ width: '100%' }} onClick={() => handleSendOTP()} disabled={status === 'SUCCESS'}>RESEND OTP</Button>
            {error && <span style={{ color: '#ed474b', textAlign: 'center' }}>{error}</span>}
        </div>
    </div>
}

export default OTP;