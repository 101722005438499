import React, { forwardRef, useEffect} from 'react';
import { Outlet, useLocation, useOutlet } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useAuth0 } from "../react-auth0-spa";
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import UploadModal from "components/Modal/UploadModal";
import constants from 'constants.js';

const Main = forwardRef((_, ref) => {
    const state = useSelector(state => state);
    const location = useLocation();
    const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
    const authUser = state.users.authUser;

    useEffect(() => {
        if (loading || isAuthenticated) return;

        const fn = async () => {
            await loginWithRedirect({
                appState: { targetUrl: location.pathname }, // appState = Seems an extra params after login - response from handleRedirectCallback. 
                connection: constants.AUTH0_DB_CONNECTION // Used in Auth0's Universal Login (Custom Login Page)
            });
        };
        
        fn();

    }, [loading, isAuthenticated, loginWithRedirect, location]);

    const loadingBackground =
        state.mainReducer.bgLoading ||
        state.users.bgLoading ||
        state.properties.bgLoading ||
        state.dashboard.bgLoading ||
        state.adminConsole.bgLoading ||
        state.propertyGroups.bgLoading;

    return <div className="main" ref={ref}>
        {loadingBackground && <LoadingSpinner size={"40px"} mask={true} />}
        {authUser && isAuthenticated === true 
            ? <Outlet />
            : <LoadingSpinner size={"40px"} />
        }
        <UploadModal />
    </div>
});

export default Main;