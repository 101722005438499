import React, { useEffect, useMemo, useState } from 'react';
import { ToastsStore } from "react-toasts";
import { useSelector } from 'react-redux';
import { Modal, Select, DatePicker, Popover } from 'antd';
import { Edit, Exit, AddFilled, Info } from 'Icons';
import useTable from 'hooks/useTable';
import useForm from 'hooks/useForm';
import ActionButton from 'elements/Button/Action';
import usePropertiesActions from 'store/actions/propertiesActions';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
// import dayjs from 'moment';
import dayjs from 'dayjs';
import './NotesModal.scss';
import Switch from 'components/Switch/Switch';

const NotesModal = ({ visible, onClose, startDate, endDate }) => {
    
    const { hotelId, activeCalendarView } = useSelector((state) => state.mainReducer);
    const { getNotes, addNote, updateNote, deleteNote } = usePropertiesActions();

    const [notes, setNotes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [formType, setFormType] = useState(null);
    const [showForm, setShowForm] = useState(false);
    useEffect(() => {
        if (visible === true) {
            loadNotes(startDate, endDate);
        }
    }, [visible]);
    
    const loadNotes = (startDate, endDate) => {
        setIsLoading(true);
        setNotes([]);
        getNotes(hotelId, startDate, endDate).then(result => {
            const data = result.data.data;
            const notes = [];
            data.forEach(item => item.data.forEach(note => {
                if (notes.find(n => n._id === note._id)) return;
                notes.push(note);
            }));
            setNotes(notes);
            setIsLoading(false);
        });
    };

    const getCheckInPeriod = (isDaily, start, end) => {
        if (isDaily && dayjs(end).diff(start, 'day') === 0) {
            return dayjs(start).format('MMM DD, YYYY');
        }
        return `${dayjs(start).format('MMM DD')}-${dayjs(end).format('DD, YYYY')}`;
    }

    const { renderHeader, renderSort, changeHeaderSort, renderHeaderKeyBatch, dataView } = useTable({ 
        headers: [
            { key: 'created_date', header: 'Created Date', sort: 'desc', render: (_, index, row) => {
                // return dayjs(row.created_date).format('YYYY-MM-DD HH:mm');
                return <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>{dayjs(row.created_date).format('MMM DD, YYYY')}</span>
                    <span style={{ fontSize: '12px', marginTop: '-3px' }}>{dayjs(row.created_date).format('hh:mm A')}</span>
                </div>;
            } }, 
            { key: 'start_date', header: 'Start Date', render: (_, index, row) => {
                return dayjs(row.start_date).format('MMM DD, YYYY');
            } }, 
            { key: 'end_date', header: 'End Date', render: (_, index, row) => {
                return dayjs(row.end_date).format('MMM DD, YYYY');
            } },
            { key: 'content', header: 'Note' }, 
            { key: 'type', header: 'Type' }, 
            { key: 'user', header: 'User', 
                value: (row) => { 
                    return row.modified_by.name 
                },
                render: (_, index, row) => {
                    const PopoverContent = <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>Created By: {row.created_by.name}</span>
                        <span>Modified Date: {dayjs(row.modified_date).format('MMMM DD, YYYY hh:mm:ss A')}</span>
                        {/* <span>Modified By: {row.modified_by.name}</span> */}
                    </div>
                    const information = <Popover content={PopoverContent}>
                        <Info height='12px' fill="#a7a7a7" style={{ cursor: 'pointer' }} />
                    </Popover>
                    const isModified = row.modified_date !== row.created_date;

                    return <div style={{ display: 'flex', justifyContent: 'center', gap: '5px' }}>
                        {row.modified_by.name} { isModified && information }
                    </div>
                }
            },
            { key: 'actions', header: 'Actions', render: (_, index, row, data, info) => {
                return <>
                    <Switch checked={row.show_dashboard_icon?? true} onChange={(checked) => {
                        updateNote(row._id, { show_dashboard_icon: checked }).then(result => {
                            if (result.type === 'UPDATE_NOTE_SUCCESS') {
                                ToastsStore.success('Note successfuly updated!');
                                loadNotes(info.start_date, info.end_date);
                            } else {
                                ToastsStore.error(`${result.error.response.data.error}`);
                            }
                        });
                    }} />
                    <Edit width="20px" height="20px" className="pointer" onClick={() => {
                        setFormType('EDIT');
                        setShowForm(true);
                        loadFormData(row)
                    }} />
                    <Exit width="20px" height="20px" className="pointer" onClick={() => {
                        setFormType('DELETE');
                        setShowForm(true);
                        loadFormData(row);
                    }}/>
                    
                </>;
            }}
        ],
        data: notes,
        info: { start_date: startDate, end_date: endDate }
    });

    const renderNotesList = () => {
        return <>
            <div className="table-container">
                { !isLoading && <table role="grid" className="notes-table">
                    <thead>
                        <tr style={{ position: 'sticky', top: 0 }}>
                            <th onClick={() => changeHeaderSort('created_date')} style={{ minWidth: '110px' }}>
                                <span>{ renderHeaderKeyBatch('created_date', renderHeader, renderSort) }</span>
                            </th>
                            <th onClick={() => changeHeaderSort('start_date')} style={{ minWidth: '90px' }}>
                                <span>{ renderHeaderKeyBatch('start_date', renderHeader, renderSort) }</span></th>
                            <th onClick={() => changeHeaderSort('end_date')} style={{ minWidth: '90px' }}>
                                <span>{ renderHeaderKeyBatch('end_date', renderHeader, renderSort) }</span></th>
                            <th onClick={() => changeHeaderSort('content')} style={{ minWidth: '200px' }}>
                                <span>{ renderHeaderKeyBatch('content', renderHeader, renderSort) }</span>
                            </th>
                            <th onClick={() => changeHeaderSort('type')} style={{ minWidth: '70px' }}>
                                <span>{ renderHeaderKeyBatch('type', renderHeader, renderSort) }</span>
                            </th>
                            <th onClick={() => changeHeaderSort('user')} style={{ minWidth: '150px' }}>
                                <span>{ renderHeaderKeyBatch('user', renderHeader, renderSort) }</span>
                            </th>
                            <th style={{ width: '60px' }}>
                                <span>{ renderHeader('actions') }</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataView.map((item, index) => 
                            <tr key={`note_${index}`}>
                                <td><div>{item.created_date}</div></td>
                                <td><div>{item.start_date}</div></td>
                                <td><div>{item.end_date}</div></td>
                                <td><div className='ellipsis'>{item.content}</div></td>
                                <td><div>{item.type}</div></td>
                                <td><div>{item.user}</div></td>
                                <td><div style={{ gap: '8px' }}>{item.actions}</div></td>
                            </tr>
                        )}
                    </tbody>
                </table> }
                { isLoading && <LoadingSpinner size="40px" text="Loading notes. Please wait ..." /> }
            </div>
        </>
    }

    const { 
        values, editedValues, errors, fields, 
        setValue, loadFormData, submitForm, getFormData 
    } = useForm({ 
        formFields: [{
            key: 'start_date',
            type: 'date',
            defaultValue: startDate,
            validations: {
                required: true
            }
        }, {
            key: 'end_date',
            type: 'date',
            defaultValue: endDate,
            validations: {
                required: true
            }
        }, {
            key: 'type',
            type: 'string',
            defaultValue: 'BLOCKING',
            validations: {
                required: true,
                enum: ['BLOCKING', 'HOLIDAY', 'ALERT']
            }
        }, {
            key: 'content',
            type: 'string',
            validations: {
                required: true,
                limit: 100
            }
        }, {
            key: 'show_dashboard_icon',
            type: 'boolean',
            defaultValue: true
        }],
        onSubmit: () => {
            const formData = getFormData();

            if (formType === 'ADD') {
                addNote({ ...formData }).then(result => {
                    if (result.type === 'ADD_NOTE_SUCCESS') {
                        ToastsStore.success('Note successfuly created!');
                        setShowForm(false);
                        loadNotes(startDate, endDate);
                    }else {
                        ToastsStore.error(`${result.error.response.data.error}`);
                    }
                });
            } else if (formType === 'EDIT') {
                updateNote(formData._id, { ...editedValues }).then(result => {
                    if (result.type === 'UPDATE_NOTE_SUCCESS') {
                        ToastsStore.success('Note successfuly updated!');
                        setShowForm(false);
                        loadNotes(startDate, endDate);
                    } else {
                        ToastsStore.error(`${result.error.response.data.error}`);
                    }
                });
            } else if (formType === 'DELETE') {
                deleteNote(formData._id).then(result => {
                    if (result.type === 'DELETE_NOTE_SUCCESS') {
                        ToastsStore.success('Note successfuly deleted!');
                        setShowForm(false);
                        loadNotes(startDate, endDate);
                    } else {
                        ToastsStore.error(`${result.error.response.data.error}`);
                    }
                })
            }
        }
    });

    const renderNotesForm = () => {
        const formData = getFormData();
        const isDisabled = formType === 'DELETE';
        // const formStartDate = formType === 'ADD' ? startDate: formData.start_date;
        // const formEndDate = formType === 'ADD' ? endDate: formData.end_date;
        
        return <form className="light-form">
            { formType === 'DELETE' && <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
                Are you sure you want to this note?
            </div>}
            <div className='light-input-container'>
                {formType !== 'DELETE' && <>
                    <div className='light-label'>Date Range</div>
                    <DatePicker.RangePicker 
                        popupClassName="note-modal-date-ranges-picker"
                        style={{ width: "100%" }}
                        value={[dayjs(formData.start_date), dayjs(formData.end_date)]}
                        size="default" format="MMMM DD YYYY"
                        onChange={(value) => {
                            setValue('start_date', dayjs(value[0]).utc().startOf('day'));
                            setValue('end_date', dayjs(value[1]).utc().startOf('day'));
                        }}
                    />
                </>}

                {formType === 'DELETE' && <div className='light-label' style={{ display: 'flex', justifyContent: 'center', fontSize: '17px' }}>
                    {dayjs(formData.start_date).isSame(formData.end_date, 'day')
                        ? dayjs(formData.start_date).format("MMMM DD, YYYY")
                        : `${dayjs(formData.start_date).format("MMM DD, YYYY")} - ${dayjs(formData.end_date).format("MMM DD, YYYY")}`
                    }
                </div>}
            </div>
            <div className='light-input-container'>
                <div className='light-label'>TYPE</div>
                <Select className='light-select' defaultActiveFirstOption={true} defaultValue={'MEMO'} disabled={isDisabled}
                    value={values.type} onChange={(val) => setValue('type', val)} optionFilterProp="children">
                    {[
                        { id: 'BLOCKING', label: 'Blocking'},
                        { id: 'HOLIDAY', label: 'Holiday'},
                        { id: 'ALERT', label: 'Alert'},
                    ].map(item => 
                        <Select.Option className='light-option' key={item.id} value={item.id}>
                            {item.label}
                        </Select.Option>
                    )}
                </Select>
                { errors?.type && <div className='light-input-error'>{Object.values(errors.type)[0]}</div> }
            </div>
            <div className='light-input-container'>
                <textarea className='light-textarea' style={{ resize: 'none' }} placeholder='Enter notes here' 
                    maxLength={100} value={values.content?? ""} onChange={(e) => setValue('content', e.target.value, true)} 
                    disabled={isDisabled}
                />
                { errors?.content && <div className='light-input-error'>{Object.values(errors.content)[0]}</div> }
            </div>
            <div className='light-input-container'>
                
                <div className='light-label'>Dashboard Icon</div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <span style={{ fontWeight: '600', fontSize: '12px'}}>{values.show_dashboard_icon? 'ACTIVE': 'INACTIVE'}</span>
                    <Switch checked={values.show_dashboard_icon ?? true} onChange={() => setValue('show_dashboard_icon', !values.show_dashboard_icon )} />
                </div>
                { errors?.type && <div className='light-input-error'>{Object.values(errors.dashboard_visible)[0]}</div> }
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '25px' }}>
                {/* <ActionButton type='cancel' text='Back' action={() => setCurrentView('list')} ></ActionButton> */}
                <ActionButton type={isLoading? 'disabled': 'cancel'} text='Cancel' action={() => setShowForm(false)} ></ActionButton>
                <ActionButton type={isLoading? 'disabled': 'submit'} text={formType === 'DELETE'? 'Delete': 'Post'} action={() => submitForm()} ></ActionButton>
            </div>
        </form>
    }

    const renderNotesHeader = () => {
        return <div className="rms-modal-header">
            <h3 style={{ display: 'flex', alignItems: 'center', gap: '8px' }}> 
                Notes 
                <span style={{ color: '#686767' }}>
                    {getCheckInPeriod(activeCalendarView === 'daily', startDate, endDate)}
                </span> 
                <AddFilled width="25px" height="25px" className="pointer" onClick={() => { 
                    setFormType('ADD');
                    setShowForm(true);
                    loadFormData({
                        property_id: hotelId,
                        view: (activeCalendarView === 'custom'? 'daily': activeCalendarView).toUpperCase(),
                        start_date: startDate,
                        end_date: endDate
                    });
                }} /> 
            </h3>
        </div>
    }
    
    return <Modal
        open={visible}
        footer={null}
        closeIcon={<Exit width="25px" height="25px" className="pointer" />}
        onCancel={() => onClose()}
        width={1100}
        title={null}
    >
        <div className="notes-modal rms-modal-content">
            {renderNotesHeader()}
            {renderNotesList()}
        </div>
        <Modal 
            open={showForm}
            footer={null}
            closeIcon={<Exit width="25px" height="25px" className="pointer" />}
            onCancel={() => setShowForm(false)}
            title={null}
            centered={true}
        >
            <div className="rms-modal-content">
                <div className="rms-modal-header">
                    <h3>
                        {formType === 'ADD' && 'Add'}
                        {formType === 'EDIT' && 'Edit'}
                        {formType === 'DELETE' && 'Delete'} Note
                    </h3>
                </div>
                {renderNotesForm()}
            </div>
        </Modal>
    </Modal>

}

export default NotesModal;