import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import RmsTabs from "../Tabs/Tabs";
import PropertyList from "./PropertyList";
import Button from "../Button/Button";
import FormField from '../Form/Field';
import usePropertyGroupActions from '../../store/actions/propertyGroupActions';
import usePropertyActions from '../../store/actions/propertiesActions';
import WithDataRequiredContainer from "../WithDataRequiredContainer/WithDataRequiredContainer";
import ConfirmationModal from "../ConfirmationModal";
import ErrorPage from "../ErrorPage/ErrorPage";
import ComparisonsList from 'components/Lists/ComparisonsList';
import { useNavigate, useParams } from 'react-router-dom';
import CustomTabs from "../CustomTabs/CustomTabs";
import VariableSettings from "../ForecastSettings/VariableSettings/VariableSettings";
import ForecastWeights from "../ForecastSettings/ForecastWeights";

import { isNullish } from 'utils/data';
import constants from "../../constants";

import {
    getUserMainTabs,
    localStorageSub,
    getChartDaysOutOptions,
    defaultPickupOptions,
    defaultPaceOptions,
    defaultViewOptions,
    getSelectedPropertyList,
    getFormFieldConfigs,
    getFormFieldDefaultValues,
    validateAllFields,
    validate,
    getIdToEdit
} from './helper';

import './PropertyGroupForm.scss';

const PropertyGroupsForm = () => {
    const navigate = useNavigate();
    const { id: propertyGroupId } = useParams();
    const state = useSelector((state) => ({
        properties: state.properties,
        propertyGroup: state.propertyGroup,
        main: state.mainReducer,
        users: state.users ?? {},
        user: state.users?.authUser ?? {},
    }));

    const { getProperties, getAllPropertiesAndGroups } = usePropertyActions();
    
    const {
        getPropertyGroups,
        loadPropertyGroupState,
        clearPropertyGroup,
        notifyUser,
        showLoadingPage,
        addPropertyGroup,
        updatePropertyGroup,
        setSelectedPropertyGroupState,
    } = usePropertyGroupActions();
    const dispatch = useDispatch();
    const [allProperties, setAllProperties] = useState([]);
    const [allGroups, setAllGroups] = useState([]);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(propertyGroupId ? true : false);
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
    const [showDeletePropertyModal, setShowDeletePropertyModal] = useState(false);
    const [deletePropertyGroupId, setDeletePropertyGroupId] = useState(null);
    const [showDeleteComparisonModal, setShowDeleteComparisonModal] = useState(false);
    const [deleteComparisonId, setDeleteComparisonId] = useState(null);
    const [pageError, setPageError] = useState(null);
    const [originalValues, setOriginalValues] = useState({});
    const [values, setValues] = useState(getFormFieldDefaultValues(getFormFieldConfigs()));
    const [errors, setErrors] = useState({});
    const [activeMainTab, setActiveMainTab] = useState(isEdit
        ? localStorageSub("managePropertyGroupTab", getUserMainTabs(state.user.type)[0]?.key)
        : getUserMainTabs(state.user.type)[0]?.key
    );

    const users = state.users;
    const userPrefs = users.authUser.prefs;
    const hideComparisons = !isNullish(userPrefs) && (userPrefs.hideComparisons ?? false);



    useEffect(() => {

        document.title = propertyGroupId
            ? "Rev & You | Edit Property Group"
            : "Rev & You | Add Property Group";

        if (isEdit) {
            Promise.all([
                getProperties("", "name", 1),
                getPropertyGroups("", "name", 1),
                loadPropertyGroupState(propertyGroupId)
            ]).then(([properties, groups, groupProperties]) => {
                const propertyList = properties?.response?.data;
                const groupList = groups?.data;
                const groupPropertyList = groupProperties?.response?.data;

                if (Array.isArray(propertyList?.data)) setAllProperties(propertyList.data);
                if (Array.isArray(groupList?.data)) setAllGroups(groupList.data);
                if (groupPropertyList?.data) {
                    setOriginalValues(groupPropertyList.data);
                    setValues(groupPropertyList.data);
                }

                dispatch({ type: constants.UPDATE_ACTIVE_HOTEL, value: { ...groupPropertyList.data, group: true } });

                setIsPageLoading(false);
            });

        } else {
            Promise.all([
                getProperties("", "name", 1),
                getPropertyGroups("", "name", 1),
            ]).then(([properties, groups]) => {
                const propertyList = properties?.response?.data;
                const groupList = groups?.response?.data;

                if (Array.isArray(propertyList?.data)) setAllProperties(propertyList.data);
                if (Array.isArray(groupList?.data)) setAllGroups(groupList.data);

                setIsPageLoading(false);
            });

            dispatch({ type: constants.UPDATE_COMPARISONS_ENABLED, value: false });
            dispatch({ type: constants.UPDATE_COMPARISONS_LATEST, value: [] });
        }

        getAllPropertiesAndGroups(propertyGroupId).then((res) => {
            if (res && res.type === "GET_PROPERTIES_AND_GROUPS_SUCCESS") {
                const data = res.response.data.data;
                dispatch({
                    type: constants.UPDATE_COMPARISONS_OPTIONS,
                    value: data
                })
            }
        });

        return () => {
            clearPropertyGroup();
        }
    }, []);

    useEffect(() => {
        setValues({ ...values, comparisons: state.main.comparisons });
    }, [state.main.comparisons])



    const formFieldEventHandler = ({ action, name, value }) => {
        setValues({ ...values, [name]: value });

        const newErrors = {};
        validate(getFormFieldConfigs(name), value).forEach(error => {
            newErrors[error.field] = error.message;
        });

        if (newErrors[name] == undefined) newErrors[name] = undefined;

        setErrors({ ...errors, ...newErrors });
    }

    const handleCancel = () => {
        if (isEdit) {
            const changedKeys = Object.keys(originalValues).filter(key => originalValues[key] != values[key]);
            if (changedKeys.length > 0) setShowUnsavedChangesModal(true);
            else navigate(-1);
        } else {
            navigate(-1);
        }
    }

    const handleSubmit = () => {
        const newErrors = {};
        validateAllFields(getFormFieldConfigs(), values).forEach(error => {
            newErrors[error.field] = error.message;
        });

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            notifyUser("ERROR", "Unable to proceed. Please check the value of the fields.");
            return;
        }

        setShowSubmitModal(true);
    }



    return (
        <div className="forms page manage-property-group">
            <div>
                <div className="container centered bg-white property-group-info-header" style={{ padding: '16px 80px 0' }}>
                    <h2>Manage Group</h2>
                    <RmsTabs
                        inputClassName={"property-group-main-tabs"}
                        tabs={getUserMainTabs(state.user.type)}
                        activeTab={activeMainTab}
                        selectTab={(key) => {
                            if (!isEdit) return;
                            localStorage.setItem("managePropertyGroupTab", key);
                            setActiveMainTab(key);
                        }}
                    />
                </div>

                <WithDataRequiredContainer
                    doneFetching={!isPageLoading}
                    height={"300px"}
                    style={{ paddingTop: '24px' }}
                >
                    {pageError ? (<ErrorPage errorMessage={pageError} />) : (
                        <div className="manage-property-group-tab-contents">

                            {/* Group Information */}
                            {activeMainTab == "groupInfo" && (
                                <div className="form-layout manage-group-info">
                                    <div className="centered-content">
                                        <div className="form-layout-row">

                                            <div style={{
                                                display: "grid", gridTemplateColumns: "60% 40%"
                                            }}>
                                                {/* Basic Group Info and Included Properties */}
                                                <div className="form-layout-column">
                                                    <div className="form-container">

                                                        <div className="property-list" style={{ marginTop: "0" }}>
                                                            <h3>Basic Information</h3>
                                                        </div>

                                                        <FormField label="NAME OF GROUP" type="textbox" name="name"
                                                            value={values.name} error={errors.name} eventHandler={formFieldEventHandler} />

                                                        <FormField label="GROUP CODE" type="textbox" name="code" disabled={isEdit}
                                                            value={values.code} error={errors.code} eventHandler={formFieldEventHandler} />

                                                        <FormField label="DESCRIPTION" type="textbox" name="description"
                                                            value={values.description} error={errors.description} eventHandler={formFieldEventHandler} />

                                                        <PropertyList
                                                            allProperties={allProperties}
                                                            className={errors.properties ? "property-list-error" : ""}
                                                            selectedProperties={values.properties ?? []}
                                                            eventHandler={(event) => {

                                                                if (event.action == "delete") {
                                                                    setShowDeletePropertyModal(true);
                                                                    setDeletePropertyGroupId(event.id);
                                                                    return;
                                                                }

                                                                if (event.action == "add") {
                                                                    const newSelectedProperties = getSelectedPropertyList(
                                                                        event,
                                                                        [...allProperties],
                                                                        [...values.properties ?? []]
                                                                    );
    
                                                                    formFieldEventHandler({
                                                                        action: "edit_properties",
                                                                        name: "properties",
                                                                        value: newSelectedProperties
                                                                    });

                                                                    return;
                                                                }
                                                                
                                                            }}
                                                        />

                                                    </div>
                                                </div>

                                                {/* Empty Container, Only Needed for Layout Purposes */}
                                                {!hideComparisons && <div className="form-layout-column">
                                                    <div className="form-container" style={{ width: "500px" }}>
                                                    </div>
                                                </div>}
                                            </div>

                                        </div>

                                        {/* Action Buttons */}
                                        <div className="form-layout-row form-options">
                                            <Button className="submit" type="primary" disabled={false}
                                                onClick={handleSubmit}>
                                                Submit
                                            </Button>
                                            <Button type="bg-dark"
                                                onClick={handleCancel}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </div>)}


                            {/* Admin Controls */}
                            {activeMainTab == "adminControls" && (
                                <div className="form-layout manage-group-admin-controls">
                                    <div className="centered-content form-layout-row">

                                        <div style={{
                                            display: "grid",
                                            gridTemplateColumns: hideComparisons ? "50% 50%" : "40% 60%"
                                        }}>
                                            {/* Default Controls */}
                                            <div className="form-layout-column">
                                                <div className="form-container" style={
                                                    hideComparisons ? null : { width: "400px", paddingRight: "40px" }
                                                }>

                                                    <div className="property-list" style={{ marginTop: "0" }}>
                                                        <h3>Default Controls</h3>
                                                    </div>

                                                    <FormField label="GROUP STATUS" type="switch" name="enabled"
                                                        value={values.enabled} error={errors.enabled} eventHandler={formFieldEventHandler} />

                                                    <FormField label="AVERAGE DAYS" type="numberbox" name="average_days" min="1"
                                                        value={values?.average_days ?? 60} error={errors.average_days} eventHandler={formFieldEventHandler} />

                                                    <FormField label="BOOKING CHART DAYS OUT" type="dropdown" name="chart_days_out"
                                                        value={values.chart_days_out} error={errors.chart_days_out} eventHandler={formFieldEventHandler}
                                                        items={getChartDaysOutOptions()} />

                                                    <FormField label="DEFAULT PICKUP" type="dropdown" name="default_pickup"
                                                        value={values.default_pickup} error={errors.default_pickup} eventHandler={formFieldEventHandler}
                                                        items={defaultPickupOptions} />

                                                    <FormField label="DEFAULT PACE" type="dropdown" name="default_pace"
                                                        value={values.default_pace} error={errors.default_pace} eventHandler={formFieldEventHandler}
                                                        items={defaultPaceOptions} />

                                                    <FormField label="DEFAULT VIEW" type="dropdown" name="default_view"
                                                        value={values.default_view} error={errors.default_view} eventHandler={formFieldEventHandler}
                                                        items={defaultViewOptions} />
                                                </div>
                                            </div>


                                            {/* Comparisons */}
                                            {!hideComparisons && <div className="form-layout-column" style={{ marginTop: "-50px" }}>
                                                <div className="form-container">
                                                    <ComparisonsList
                                                        id={propertyGroupId}
                                                        title="Comparisons"
                                                        active={values.comparisons_enabled}
                                                        selected={values.comparisons ?? []}
                                                        className={errors.comparisons ? "property-list-error" : ""}
                                                        action={(event) => {
                                                            if (event.action == "delete") {
                                                                setShowDeleteComparisonModal(true);
                                                                setDeleteComparisonId(event.id);
                                                                return;
                                                            }

                                                            formFieldEventHandler({
                                                                action: "edit_comparisons",
                                                                name: "comparisons",
                                                                value: state.main.comparisons
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>}
                                        </div>


                                        {/* Submit & Cancel */}
                                        <div className="form-layout-row form-options" style={{ marginTop: "20px" }}>
                                            <Button className="submit" type="primary" disabled={false}
                                                onClick={handleSubmit}>
                                                Submit
                                            </Button>

                                            <Button type="bg-dark" onClick={handleCancel}>
                                                Cancel
                                            </Button>
                                        </div>

                                    </div>
                                </div>)}


                            {/* Forecast Settings */}
                            {activeMainTab == "forecastSettings" && values && (<div>
                                <CustomTabs name="forecastSettingsTab" tabs={[
                                    { key: 'variable_settings', label: 'Variable Settings' },
                                    { key: 'forecast_settings_new', label: 'Forecast Weights' },
                                    { key: 'forecast_settings', label: 'Forecast Weights(v1)', hide: true }
                                ]}>
                                    <CustomTabs.Tab key="variable_settings">
                                        <VariableSettings isGroup={true} values={values} />
                                    </CustomTabs.Tab>
                                    <CustomTabs.Tab key="forecast_settings_new">
                                        <ForecastWeights isGroup={true} values={values} />
                                    </CustomTabs.Tab>
                                </CustomTabs>
                            </div>)}

                        </div>)}
                </WithDataRequiredContainer>
            </div>


            {/* Confirm Submit Modal */}
            <ConfirmationModal
                visible={showSubmitModal}
                text={<center><p>{isEdit ? "Are you sure you want to submit changes?" : "Create new property?"}</p></center>}
                eventHandler={({ action }) => {
                    const getPropertyGroupData = () => {
                        const propertyGroupData = { ...values };
                        if (Array.isArray(propertyGroupData.properties))
                            propertyGroupData.properties = propertyGroupData.properties.map(property => property.id);
                        
                        propertyGroupData.status = values.enabled ? "active" : "inactive";

                        if (!hideComparisons) {
                            propertyGroupData.comparisons_enabled = state.main.comparisons_enabled;
                            propertyGroupData.comparisons = state.main.comparisons.map((comparison) => {
                                return isNullish(comparison.isGroup)
                                    ? {
                                        id: comparison.id,
                                        isGroup: !("property_code" in comparison),
                                        property: comparison.id,
                                        properties: comparison.properties ?? [comparison.id],
                                        name: comparison.name,
                                        code: comparison.property_code ?? comparison.code,
                                    }
                                    : isNullish(comparison.id)
                                        ? { ...comparison, id: comparison.property }
                                        : comparison
                            })
                        }

                        return propertyGroupData;
                    }

                    switch (action) {
                        case "submit":
                            setShowSubmitModal(false);
                            showLoadingPage(true);
                            if (isEdit) {
                                updatePropertyGroup(propertyGroupId, getPropertyGroupData()).then((response) => {
                                    if (response?.data?.data) {
                                        setSelectedPropertyGroupState(response?.data?.data);
                                        notifyUser("SUCCESS", "Property Group was successfuly updated!");
                                    } else {
                                        notifyUser("ERROR");
                                    }
                                }).catch((error) => {
                                    notifyUser("ERROR", "Failed to update Property Group!");
                                }).finally(() => {
                                    showLoadingPage(false);
                                });
                            } else {
                                addPropertyGroup(getPropertyGroupData()).then((response) => {
                                    notifyUser("SUCCESS", "Property Group was successfuly created!");
                                }).catch((error) => {
                                    notifyUser("ERROR", "Failed to add Property Group!");
                                }).finally(() => {
                                    showLoadingPage(false);
                                    setValues(getFormFieldDefaultValues(getFormFieldConfigs()));
                                    setActiveMainTab(getUserMainTabs(state.user.type)[0]?.key);
                                });
                            }

                            break;
                        case "cancel":
                            setShowSubmitModal(false);
                            break;
                    }
                }}
            />


            {/* Delete Property from Group Modal */}
            <ConfirmationModal visible={showDeletePropertyModal}
                text={(<center>
                    <p>
                        Removing this property from the group will remove the property’s data from the group.
                        <br />
                        Are you sure you want to remove <b>{
                            allProperties.find(property => property.id == deletePropertyGroupId)?.name}
                        </b> from this group?
                    </p>
                </center>)}
                width={580}
                confirmText="Submit"
                cancelText="Cancel"
                eventHandler={({ action }) => {
                    switch (action) {
                        case "submit":
                            formFieldEventHandler({
                                action: "edit_properties",
                                name: "properties",
                                value: getSelectedPropertyList(
                                    { action: "delete", id: deletePropertyGroupId },
                                    [...allProperties],
                                    [...values.properties ?? []]
                                )
                            });
                            setShowDeletePropertyModal(false)
                            break;
                        case "cancel": setShowDeletePropertyModal(false); break;
                    }
                }}
            />


            {/* Delete Comparison Modal */}
            <ConfirmationModal visible={showDeleteComparisonModal}
                text={(<p><br />
                    Are you sure you want to remove <b>{
                        state.main.comparisons_options.find(_ => _.id === deleteComparisonId)?.name
                    }</b> from the comparisons list?
                </p>)}
                width={580}
                confirmText="Submit"
                cancelText="Cancel"
                eventHandler={({ action }) => {
                    switch (action) {
                        case "submit":
                            const comparisons = state.main.comparisons;
                            const updatedComparisons = comparisons.filter(_ => _.id !== deleteComparisonId);
                            dispatch({
                                type: constants.UPDATE_COMPARISONS_LATEST,
                                value: updatedComparisons
                            })
                            setValues({ ...values, comparisons: updatedComparisons });

                            formFieldEventHandler({
                                action: "edit_comparisons",
                                name: "comparisons",
                                value: updatedComparisons
                            });
                            setShowDeleteComparisonModal(false)
                            break;
                        default:
                            setShowDeleteComparisonModal(false);
                            break;
                    }
                }}
            />


            {/* Unsaved Changes Modal */}
            <ConfirmationModal visible={showUnsavedChangesModal}
                text={<center><p>You have unsaved changes. Are you sure you want to leave this page?</p></center>}
                confirmText="Yes"
                cancelText="Cancel"
                width={500}
                eventHandler={({ action }) => {
                    switch (action) {
                        case "submit": navigate(-1); break;
                        case "cancel": break;
                    }
                    setShowUnsavedChangesModal(false);
                }}
            />
        </div>
    )

}

export default PropertyGroupsForm;
