import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Funnel } from '../../../../Icons';
import { Popover } from 'antd'
import FilledIcon from 'elements/Icon/FilledIcon';
import OutlinedIcon from 'elements/Icon/OutlinedIcon';
import Button from "../../../Button/Button";
import { checkIfMoment, populateData, transformReservationsData } from "./helper";
import Select from 'elements/Select';
import './ReservationsList.scss';

const COLOR_ICON_DEFAULT = "#d2d2d2";
const COLOR_ICON_ACTIVE = "#51b68a"
const COLOR_ORANGE = "#f28f3c";
const COLOR_FONT_DEAFULT = "#231f20";

const ReservationList = ({ data = {} }) => {
    // console.log(data);
    const { calendar_data, property } = useSelector(state => state.mainReducer);

    const [rawData, setRawData] = useState([]);
    const [viewData, setViewData] = useState([]);
    const [headers, setHeaders] = useState({});

    useEffect(() => {
        setRawData(transformReservationsData(property, data));
        setHeaders({ ...headers, arrival: { sort: "desc" }});
    }, []);
    
    useEffect(() => {
        setViewData(populateData(headers, rawData));
    }, [rawData, headers]);
    
    const renderHeader = (dataKey, text = "") => {

        const filterOptions = [];
        const currentHeader = headers[dataKey];
        const currentSort = currentHeader?.sort;
        const currentFilter = currentHeader?.filter?? [];
        
        viewData.forEach(data => {
            const value = (checkIfMoment(data[dataKey])? data[dataKey].format("MMM DD, YYYY"): data[dataKey]);
            if (!filterOptions.some(_ => _.value == value)) {
                filterOptions.push({ label: value, value: value })
            }
        });

        const handleSort = (dataKey) => {
            const toggleSort = (value) => {
                if (value === "asc") return "desc";
                if (value === "desc") return null;
                return "asc";
            }
            const newHeaders = { ...headers };
            newHeaders[dataKey] = newHeaders[dataKey]
                ? { ...newHeaders[dataKey], sort: toggleSort(currentSort) }
                : { sort: toggleSort(currentSort) };
            Object.keys(newHeaders).forEach(key => {
                if (dataKey == key) return;
                newHeaders[key].sort = null;
            });
            
            setHeaders(newHeaders);
        }

        const HeaderFilter = ({ headers, setHeaders, text, options, selectedOptions = [] }) => {
            console.log(options);
            selectedOptions = selectedOptions.filter(selectedOption => {
                return options.some(option => option.id == selectedOption);
            });

            const [selectedItems, setSelectedItems] = useState(selectedOptions);
            const [isVisible, setVisibility] = useState(false);

            const handleVisibleChange = (val) => {
                setVisibility(val);
            }

            const handleFilter = (items) => {
                const field = { ...headers[dataKey], filter: items };

                setHeaders({...headers, [dataKey]: field });
                setVisibility(false);
            }
            const content = (
                <div className="modal-reservation-list-table-header-popover">
                    <div className="light-form-buttons" style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }} >
                        <Button type="bg-light" style={{ marginRight: '8px' }} onClick={(e) => handleFilter([]) }>Reset</Button>
                        <Button btnType="submit" type="primary" onClick={(e) => { handleFilter(selectedItems) }}>Apply</Button>
                    </div>
                    <div> 
                        <Select 
                            mode={"multiple"} 
                            options={options}
                            value={selectedItems}
                            placeholder={"Type or select here to filter"}
                            dropdownClassName={"filter-search-bar-dropdown"}
                            removeIcon={<FilledIcon type='close-circle' />}
                            notFoundContent={"No available options found"}
                            defaultActiveFirstOption={false}
                            maxTagCount={0}
                            onChange={keys => {
                                setSelectedItems(keys);
                            }}
                            style={{ width: '100%' }}
                            showSearch={true}
                        />
                    </div>
                </div>
            );

            return (
                <Popover open={isVisible} onOpenChange={handleVisibleChange} placement="rightTop" title={text} trigger="click" content={content}>
                    <Funnel width="14px" height="14px" fill={currentFilter.length > 0 ? COLOR_ICON_ACTIVE: COLOR_ICON_DEFAULT} />
                </Popover>
            )
        }

        return (<>
            <span style={{ display: "flex", alignItems: "center", color: currentSort || currentFilter.length > 0? COLOR_ICON_ACTIVE: "#231f20" }} onClick={() => {}}>
                <span title={"Click to sort"} onClick={() => handleSort(dataKey) }>{text}</span>
                <div className="modal-reservation-list-table-header-icons">
                    <HeaderFilter headers={headers} setHeaders={setHeaders} text={`FILTER ${text.toUpperCase()}`} options={filterOptions} selectedOptions={currentFilter} />
                    { currentSort && <span>
                        <OutlinedIcon type='up' style={{
                            fontSize: "12px",
                            color: currentSort === "asc"? COLOR_ICON_ACTIVE: COLOR_ICON_DEFAULT,
                            display: "block",
                            position: "relative",
                            top: "2.5px",
                        }}/>
                        <OutlinedIcon type='down' style={{
                            fontSize: "12px",
                            color: currentSort === "desc"? COLOR_ICON_ACTIVE: COLOR_ICON_DEFAULT,
                            display: "block",
                            position: "relative",
                            bottom: "2.5px",
                        }}/>
                    </span> }
                </div>                
            </span>
        </>)
    }

    const userSelectedSegments = calendar_data.segments;
    const segmentHeader = userSelectedSegments.includes("ALL SEGMENTS") || userSelectedSegments.some(s => s.includes("GROUP"))
        ? "Segment Group"
        : "Segment";

    return (
    <table className="modal-reservations-list">
        <thead >
            <tr style={{ height: "40px", position: "sticky", top: 0, background: "#fff" }}>
                <th>{ renderHeader("reservation_id", "Reservation ID") }</th>
                <th>{ renderHeader("guest_name", "Guest Name") }</th>
                <th>{ renderHeader("status", "Status") }</th>
                <th>{ renderHeader("total_occ", "Rooms") }</th>
                <th>{ renderHeader("segment_name", segmentHeader) }</th>
                <th>{ renderHeader("arrival", "Arrival") }</th>
                <th>{ renderHeader("departure", "Departure") }</th>
                <th>{ renderHeader("adr", "*ADR") }</th>
            </tr>
        </thead>
        <tbody>
            {viewData.map((item, index) => <tr key={index}>
                <td title={ item.reservation_id }>{ item.reservation_id }</td>
                <td title={ item.guest_name }>{ item.guest_name }</td>
                <td title={ item.status }>{ item.status }</td>
                <td title={ item.total_occ }>{ item.total_occ }</td>
                <td title={ item.segment_name }>{ item.segment_name }</td>
                <td title={ item.arrival }>{ item.arrival.format("MMM DD, YYYY") }</td>
                <td title={ item.departure }>{ item.departure.format("MMM DD, YYYY") }</td>
                <td title={ item.adr }>{ item.adr }</td>
            </tr>)}
        </tbody>
    </table>
    );
}

export default ReservationList;