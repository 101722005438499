import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Modal } from "antd";
import { Exit } from "Icons";

import Button from "../Button/Button";
import Form from "./Form";
import useForm from "./useForm";
import validateForm from "./validateForm";
import Field from "../Field";
import PropertyMapping from "../PropertyMapping/PropertyMapping";
import UnsavedChangesModal from "./UnsavedChangesModal";
import WithDataRequiredContainer from "../WithDataRequiredContainer/WithDataRequiredContainer";

import constants from "../../constants";
import useUsersActions from "../../store/actions/usersActions";
import { withAuthorization } from "../../session";
import { user_information, user_permissions } from "./UsersFields";
import { useLocation, useNavigate, useParams } from "react-router-dom";



const UsersForm = () => {
    const navigate = useNavigate();
    const { id: userId } = useParams();
    const location = useLocation();
    const state = useSelector((state) => state.users);
    const [internalState, setState] = useState({
        isEdit: false,
        showModal: false,
        showUnsavedWarning: false,
    });
    const [username, setUsername] = useState("");

    const {
        addUser,
        getUser,
        updateUser,
        getUsers,
        clearUser,
    } = useUsersActions();

    const combinedFields = [...user_information, user_permissions];

    let initialState = {
        properties: [],
    };

    combinedFields.map((field) => {
        initialState[field.key] = field.initialValue === 0 ? 0 : field.initialValue;
    });

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        editedValues,
        errors,
        prePopulateForm,
    } = useForm(
        submit,
        validateForm,
        combinedFields,
        initialState,
        internalState.isEdit
    );
    useEffect(() => {
        if (userId) {
            setState({ ...internalState, isEdit: true });
            getUser(userId).then((res) => {
                if (res.type === constants.GET_USER_SUCCESS) {
                    const fetchedUser = res.response.data.data;
                    prePopulateForm(fetchedUser);
                    fetchedUser["name"] && setUsername(fetchedUser["name"]);
                }
            });
        }

        return () => {
            clearUser();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function submit() {
        let data;
        if (internalState.isEdit) {
            // filter updated values only
            data = { ...editedValues };
            if (editedValues.properties) {
                data.properties = data.properties.map(property => {
                    return { _id: property._id }
                });
            }
            updateUser(userId, data);
        } else {
            data = { ...values };
            if (Array.isArray(data.properties)) {
                data.properties = data.properties.map(property => {
                    return { _id: property._id }
                });
            }
            // validate first
            addUser(data).then((res) => {
                if (res.type === "CREATE_USER_SUCCESS") {
                    getUsers().then((r) => {
                        navigate(-1);
                    });
                }
            });
        }
    }

    const handleCancel = () => {
        const withEditedValues = Object.keys(editedValues).length > 0;
        if (internalState.isEdit && withEditedValues) {
            setState({ ...internalState, showUnsavedWarning: true });
        } else {
            navigate(-1);
        }
    };

    const roleOptions = [
        { value: "ope_admin", label: "Rev&You Admin" },
        { value: "internal_user", label: "Internal User" },
        { value: "hotel_admin", label: "Property Admin" },
        { value: "hotel_user", label: "Property User" },
    ].filter((item) => {
        if (item.value === "ope_admin") {
            return state.authUser.type === "ope_admin" ? true : false;
        } else {
            return true;
        }
    });

    return (
        <div className="forms page">
            <Helmet>
                <title>
                    Rev&You |{" "}
                    {location.pathname.includes("add")
                        ? "Add User"
                        : "Edit | " + username}
                </title>
            </Helmet>
            <div className="divider"></div>
            <div className="container centered">
                <div className="row">
                    <div className="forms-header">
                        <h2>MANAGE USERS</h2>
                    </div>
                </div>
            </div>
            <WithDataRequiredContainer
                doneFetching={
                    location.pathname.includes("add") ||
                    state.user ||
                    !state.loadingUser
                }
                height={"300px"}
            >
                <div className="container centered">
                    <div className="row">
                        <div className="column">
                            <Form
                                fields={user_information}
                                title={"User Information"}
                                onChange={handleChange}
                                formValues={values}
                                fieldErrors={errors}
                                onBlur={handleBlur}
                            />

                            {/* <Form
              fields={user_permissions}
              title={"Permissions"}
              onChange={handleChange}
              formValues={values}
              fieldErrors={errors}
              onBlur={handleBlur}
            /> */}
                        </div>
                        <div className="column">
                            {state.permissions.canChangePermissions && (
                                <div className="forms-section">
                                    <h3>Permissions</h3>
                                    <Field
                                        key={user_permissions.key}
                                        field={user_permissions}
                                        fieldKey={user_permissions.key}
                                        label={user_permissions.label}
                                        type={user_permissions.type}
                                        value={values[user_permissions.key]}
                                        placeholder=""
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errors={errors[user_permissions.key]}
                                        options={roleOptions}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <PropertyMapping
                            mappedProperties={values.properties}
                            handleChange={handleChange}
                        />
                    </div>
                    <div className="row">
                        <div className="action-wrapper">
                            <Button
                                className="submit"
                                type="primary"
                                onClick={() => setState({ ...internalState, showModal: true })}
                            >
                                {" "}
                Submit{" "}
                            </Button>
                            <Button onClick={handleCancel}> Cancel</Button>
                        </div>
                    </div>
                </div>
            </WithDataRequiredContainer>
            <Modal
                open={internalState.showModal}
                // title={`${range1} vs ${range2}`}
                // onOk={this.handleOk}
                closeIcon={<Exit width="25px" height="25px" className="pointer" />}
                onCancel={() => setState({ ...internalState, showModal: false })}
                width={500}
                footer={null}
            >
                <div className="rms-modal-content">
                    <p>
                        {userId
                            ? "Are you sure you want to submit changes?"
                            : "Create new user?"}
                    </p>
                    <div className="modal-buttons">
                        <Button
                            type="bg-light"
                            onClick={() => setState({ ...internalState, showModal: false })}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                handleSubmit();
                                setState({ ...internalState, showModal: false });
                            }}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </Modal>
            <UnsavedChangesModal
                visible={internalState.showUnsavedWarning}
                onCancel={() =>
                    setState({ ...internalState, showUnsavedWarning: false })
                }
                onConfirm={() => {
                    navigate(-1);
                }}
            />
        </div>
    );
};

export default withAuthorization("canManageUsers")(UsersForm);
