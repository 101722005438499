import { useDispatch } from "react-redux";
import { useAuth0 } from "../../react-auth0-spa";
import constants from '../../constants';
import axios from "axios";

const useOTP = () => {

    const { getTokenSilently } = useAuth0();

    const sendOTP = async () => {
        const token = await getTokenSilently();
        
        return axios.get(`${constants.BASE_URL_2}/auth/otp`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    const verifyOTP = async (code) => {
        const token = await getTokenSilently();
    
        return axios.get(`${constants.BASE_URL_2}/auth/otp/${code || '-'}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    }

    return {
        sendOTP, 
        verifyOTP
    }
}


export default useOTP;